import jwtDecode  from "jwt-decode";
import { UteUser } from './uteUser';

export class UserLogged extends UteUser {
    aud: number;
    exp: Date;
    sub: string;
    token: string;
    lang:string;

    constructor(token:string ) {
        super();
        let tokenInfo = this.getDecodedAccessToken(token); // decode token
        //console.log(tokenInfo);
        this.token = token;
        if (token!== null) {
            this.utE_Email1 = tokenInfo['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
            this.utE_Livello = parseInt(tokenInfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);
            this.exp = new Date(tokenInfo.exp);
            this.utE_Id = parseInt(tokenInfo['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid']);
            this.utE_Abilitato = parseInt(tokenInfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/ispersistent']);
            this.utE_Nome = tokenInfo['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
            this.utE_Sede = tokenInfo['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/streetaddress'];
            this.utE_Informativa = parseInt(tokenInfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata']);
          }
    }

    getDecodedAccessToken(token: string): any {
        try{
            return jwtDecode(token);
        }
        catch(Error){
            return null;
        }
      }
}
