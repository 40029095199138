import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { TknAppToken } from '../objects/tknAppToken';
import { GenericCrudService } from './generic-crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService extends GenericCrudService<TknAppToken,number> {

  constructor( protected http: HttpClient, protected appConfigService:AppConfigService)
  {
    super(http ,appConfigService,"AppToken");
  }

  readByUser(userId:number): Observable<TknAppToken[]> {
    return this.http.get<TknAppToken[]>(`${this.endpoint}/${this.apiName}/${userId}`);
  }
}
