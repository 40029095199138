import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FlvFlavour } from '../objects/flvFlavour';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class FlavourService{
  endpoint: string;
  apiName:string='flavours';

  constructor( protected http: HttpClient, protected appConfigService:AppConfigService)
  {
    this.http=http;
    this.endpoint = appConfigService.apiBaseUrl;
  }

  read(serialNumberMs:number,modCode:number): Observable<FlvFlavour[]> {
    //this.loading = true;

    console.log(this.apiName);
    return this.http.get<FlvFlavour[]>(`${this.endpoint}/${this.apiName}?ms_serial=${serialNumberMs}&mod_code=${modCode}`);
  }

  postCustomList(serialNumberMs:number,modCode:number,objList:FlvFlavour[]){
    console.log(this.apiName);
    return this.http.post<any>(`${this.endpoint}/${this.apiName}?ms_serial=${serialNumberMs}&mod_code=${modCode}`, objList);
  }
}
