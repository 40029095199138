import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AumAssociationUserMachine } from '../objects/aumAssociationUserMachine';
import { AppConfigService } from './app-config.service';
import { GenericCrudService } from './generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class AssociationService extends GenericCrudService<AumAssociationUserMachine,number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
   super(http ,appConfigService,"machineUserAssociation");
   this.load();
 }

 get(serialNumber:number): Observable<AumAssociationUserMachine> {
  //this.loading = true;

  console.log(this.apiName);
  return this.http.get<AumAssociationUserMachine>(`${this.endpoint}/${this.apiName}/serialNumberMs=`+serialNumber);
}

}
