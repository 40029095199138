import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { Observable } from 'rxjs';
import { ModElencoModelli } from 'src/app/objects/modElencoModelli';
import { ModelService } from 'src/app/services/model.service';
import { ConfigUtil } from '../configUtils';
import { MacMacchine } from 'src/app/objects/macMacchine';

@Injectable({
  providedIn: 'root'
})
export class MachineFlavourGuard implements CanActivate {

  private listModels: ModElencoModelli[];
  
  constructor(private sessionService: SessionService,private router: Router, private modelService:ModelService){

    this.modelService.listObservable.subscribe(
      (data: any[]) => {
        this.listModels = data;
        },
      error => console.log('error'),
      ()=>console.log('completeLoad')
    )
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let machineSession:MacMacchine = JSON.parse(this.sessionService.getGenericSession(ConfigUtil.machineSessioned));
      var model = this.listModels.find(x=>x.moD_Codice==machineSession.maC_ModelloSx)
      
      if(model.moD_RMT.rmT_Code===3 || model.moD_RMT.rmT_Code===2)
        return true;
      else{
        return this.router.parseUrl("/dashboard");
      }
  }
  
}
