import { Pipe, PipeTransform } from '@angular/core';
import { PipeUnTransform } from './pipe-untransform';

@Pipe({
  name: 'hourMinute'
})
export class HourMinutePipe implements PipeUnTransform {
  untransform(value: any, ...args: any[]) {
    return Number(value.split(":")[0])*60  + Number(value.split(":")[1]);
  }
  

  transform(value: number, ...args: unknown[]): string {
   return new Date(value * 60 * 1000).toISOString().substr(11, 8);
    
  }

}
