import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'softTouchStatusIcon'
})
export class SoftTouchStatusIconPipe implements PipeTransform {

  transform(status: any): any {
    if (status==null)
      return null;
    if (status.heatCycle?.active || status.heatCycle?.active )
      return 'thermostat';
    if(status.stop)
      return 'stop';
    if(status.production.active)
      return 'play_arrow';
    if(status.nightConservation?.active)
      return 'dark_mode';
    if (status.barrelBeater?.active || status.beater?.active )
      return 'refresh';
    if (status.extraction?.active )
      return 'arrow_drop_down';
    if (status.cleaning?.active )
      return 'shower';
    else
    return 'stop';
  }
}
