<div class="main-container">
  <div class="inside-main-container">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{"select_all"|translate}}</mat-card-title>
        <mat-slide-toggle
          class="example-margin"
          [checked]="activeAll"
          (change)="allSelectionChanged($event)"
        >
        </mat-slide-toggle>
      </mat-card-header>
      <mat-card-content>
        <mat-accordion class="not">
          <mat-expansion-panel
            class="not"
            *ngFor="let model of modelNotificationList"
            [expanded]="!model.activeModel"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <b>{{ model.model.moD_Name }}</b>
              </mat-panel-title>
              <mat-panel-description >
                <mat-slide-toggle
                  class="example-margin"
                  [checked]="model.activeModel"
                  (change)="modelSelectionChanged($event, model)"
                >
                </mat-slide-toggle>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-expansion-panel
              class="not"
              *ngFor="let type of model.types"
              [expanded]="!type.activeType"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ typeNames[type.name] | translate }}
                </mat-panel-title>
                <mat-panel-description>
                  <mat-slide-toggle
                    class="example-margin"
                    [checked]="type.activeType"
                    (change)="typeSelectionChanged($event, type, model)"
                  >
                  </mat-slide-toggle>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <mat-list role="list">
                <mat-list-item
                role="listitem"
                  *ngFor="let element of type.list"
                >
                <label >
                  {{ element.description }}
                </label>
                  <mat-checkbox
                      [(ngModel)]="element.active"
                      labelPosition="before"
                      (ngModelChange)="elementSelectionChanged(element,type,model)"
                      >
                  </mat-checkbox>

                </mat-list-item>
              </mat-list>
            </mat-expansion-panel>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-raised-button color="primary" (click)="save()">save</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
