import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgotpassword-modal',
  templateUrl: './forgotpassword-modal.component.html',
  styleUrls: ['./forgotpassword-modal.component.scss'],
})
export class ForgotpasswordModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ForgotpasswordModalComponent>,
    private snackbarService: SnackbarService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {}

  openDialog() {
    this.snackbarService.showSnackBar(this.translateService.instant('operation_successfully'));
    this.dialogRef.close();
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
