import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "./language.service";


/**
 * Utility service necessary to translate the mat-paginator
 * References:
 * https://material.angular.io/components/paginator/overview
 * https://stackoverflow.com/questions/46869616/how-to-use-matpaginatorintl
 */
@Injectable()
export class MatPaginationIntlService extends MatPaginatorIntl {
  constructor(private translateService: TranslateService, private languageService: LanguageService) {
    super();

    // React whenever the language is changed
    this.translateService.onLangChange.subscribe((_event: Event) => {
      this.translateLabels();
    });

    // Initialize the translations once at construction time
    const lang = localStorage.getItem('lngDefault')?localStorage.getItem('lngDefault'):'it';
    this.translateService.use(lang);
    this.translateLabels();
  }
ß
  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    const of = this.translateService.instant("of");
    if (length === 0 || pageSize === 0) {
      return "0 " + of + " " + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;

    const endIndex = Math.min(startIndex + pageSize, length);
    return startIndex + 1 + " - " + endIndex + " " + of + " " + length;
  };

  injectTranslateService(translate: TranslateService): void {
    this.translateService = translate;

    this.translateService.onLangChange.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  translateLabels(): void {
    super.firstPageLabel = this.translateService.instant("first_page");
    super.itemsPerPageLabel = this.translateService.instant("items_per_page");
    super.lastPageLabel = this.translateService.instant("last_page");
    super.nextPageLabel = this.translateService.instant("next_page");
    super.previousPageLabel = this.translateService.instant("previous_page");
    this.changes.next(); // Fire a change event to make sure that the labels are refreshed
  }
}
