import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Material components
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MqttModule, IMqttServiceOptions} from 'ngx-mqtt';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LogoutModalComponent } from './modals/logout-modal/logout-modal.component';
import { AlarmsComponent } from './alarms/alarms.component';
import { AboutModalComponent } from './modals/about-modal/about-modal.component';
import { LoginComponent } from './login/login.component';
import { CommandsComponent } from './commands/commands.component';
import { ForgotpasswordModalComponent } from './modals/forgotpassword-modal/forgotpassword-modal.component';
import { ModifyComponent } from './modify/modify.component';
import { AssociationsComponent } from './associations/associations.component';
import { ModifyuserModalComponent } from './modals/modifyuser-modal/modifyuser-modal.component';
import { EditassociationModalComponent } from './modals/editassociation-modal/editassociation-modal.component';
import { UsersComponent } from './users/users.component';
import { SingleunitComponent } from './singleunit/singleunit.component';
import { RealtimeComponent } from './realtime/realtime.component';
import { FlavoursComponent } from './flavours/flavours.component';
import { CountersComponent } from './counters/counters.component';
import { HeatrreatmentsComponent } from './heatrreatments/heatrreatments.component';
import { PaymentsPipe } from './utils/pipes/payments.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnalysisComponent } from './analysis/analysis.component';
import { ChartsModule } from 'ng2-charts';
import { CommonModule, DatePipe } from '@angular/common';
import { RealtimeSoftTouchComponent } from './realtime/realtime-soft-touch/realtime-soft-touch.component';
import { RealtimeGxComponent } from './realtime/realtime-gx/realtime-gx.component';
import { RealtimePebComponent } from './realtime/realtime-peb/realtime-peb.component';
import { RealtimeTitanComponent } from './realtime/realtime-titan/realtime-titan.component';
import { RealtimeSoftComponent } from './realtime/realtime-soft/realtime-soft.component';
import { RealtimeMantComponent } from './realtime/realtime-mant/realtime-mant.component';
import { RealtimeTwinchefComponent } from './realtime/realtime-twinchef/realtime-twinchef.component';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { ModelPipe } from './utils/pipes/model.pipe';
import { UserPipe } from './utils/pipes/user.pipe';
import { RolePipe } from './utils/pipes/role.pipe';
import { UserInRolePipe } from './utils/pipes/user-in-role.pipe';
import { SortPipe } from './utils/pipes/sort.pipe';
import { MaterialModule } from './material-module';
import { FlavourListComponent } from './flavour-list/flavour-list.component';
import { FlavourNamePipe } from './utils/pipes/flavour-name.pipe';
import { CommandNamePipe } from './utils/pipes/command-name.pipe';
import { AlarmNamePipe } from './utils/pipes/alarm-name.pipe';
import { ParameterNamePipe } from './utils/pipes/parameter-name.pipe';
import { RecipeBookComponent } from './recipe-book/recipe-book.component';
import { EditRecipeComponent } from './modals/edit-recipe/edit-recipe.component';
import { RecipeTwistPipe } from './utils/pipes/recipe-twist.pipe';
import { RecipeTwistFormComponent } from './forms/recipe-twist-form/recipe-twist-form.component';
import { RecipeSoftFormComponent } from './forms/recipe-soft-form/recipe-soft-form.component';
import { SoftStatusIconPipe } from './utils/pipes/soft-status-icon.pipe';
import { SoftTouchStatusIconPipe } from './utils/pipes/soft-touch-status-icon.pipe';
import { CountersSoftComponent } from './counters/counters-soft/counters-soft.component';
import { CountersSoftTouchComponent } from './counters/counters-soft-touch/counters-soft-touch.component';
import { SwVersionPipe } from './utils/pipes/sw-version.pipe';
import { KgCounterPipe } from './utils/pipes/kg-counter.pipe';
import { AnalysisTouchComponent } from './analysis/analysis-touch/analysis-touch.component';
import { AnalysisLcdComponent } from './analysis/analysis-lcd/analysis-lcd.component';
import { LoaderInterceptor } from './services/loader-interceptor.service';
import { RealtimeTwistComponent } from './realtime/realtime-twist/realtime-twist.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PolicyModalComponent } from './modals/policy-modal/policy-modal.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginationIntlService } from './services/mat-paginator-intl-service';
import { WeightPipe } from './utils/pipes/weight.pipe';
import { FirmwaresComponent } from './firmwares/firmwares.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { DeleteModalComponent } from './modals/delete-modal/delete-modal.component';
import { RealtimeChefComponent } from './realtime/realtime-chef/realtime-chef.component';
import { ParameterCtrlComponent } from './parameter-ctrl/parameter-ctrl.component';
import { ButtonsEditingParametersComponent } from './buttons-editing-parameters/buttons-editing-parameters.component';
import { ParameterListComponent } from './parameter-list/parameter-list.component';
import { OnOffPipe } from './utils/pipes/on-off.pipe';
import { ParameterLinearComponent } from './parameter-linear/parameter-linear.component';
import { ParameterComponent } from './parameter/parameter.component';
import { ParameterNonLinearComponent } from './parameter-non-linear/parameter-non-linear.component';
import { ParametersDescrPipe } from './utils/pipes/parameters-descr.pipe';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { RecipeSoftHtFormComponent } from './forms/recipe-soft-ht-form/recipe-soft-ht-form.component';
import { HourMinutePipe } from './utils/pipes/hour-minute.pipe';


 // AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  console.log(http);
  return new TranslateHttpLoader(http);
}

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'mqtt.micro-systems.it',
  port: 9883,
  protocol: 'wss',
  path: '/mqtt'
};

@NgModule({
  declarations: [
    AppComponent,
    LogoutModalComponent,
    DashboardComponent,
    AlarmsComponent,
    AboutModalComponent,
    LoginComponent,
    CommandsComponent,
    ForgotpasswordModalComponent,
    ModifyComponent,
    AssociationsComponent,
    UsersComponent,
    ModifyuserModalComponent,
    EditassociationModalComponent,
    SingleunitComponent,
    RealtimeComponent,
    FlavoursComponent,
    CountersComponent,
    HeatrreatmentsComponent,
    PaymentsPipe,
    AnalysisComponent,
    RealtimeSoftTouchComponent,
    RealtimeGxComponent,
    RealtimePebComponent,
    RealtimeTitanComponent,
    RealtimeSoftComponent,
    RealtimeMantComponent,
    RealtimeTwinchefComponent,
    ModelPipe,
    UserPipe,
    RolePipe,
    UserInRolePipe,
    SortPipe,
    FlavourListComponent,
    FlavourNamePipe,
    CommandNamePipe,
    AlarmNamePipe,
    ParameterNamePipe,
    RecipeBookComponent,
    EditRecipeComponent,
    RecipeTwistPipe,
    RecipeTwistFormComponent,
    RecipeSoftFormComponent,
    SoftStatusIconPipe,
    SoftTouchStatusIconPipe,
    CountersSoftComponent,
    CountersSoftTouchComponent,
    SwVersionPipe,
    KgCounterPipe,
    AnalysisTouchComponent,
    AnalysisLcdComponent,
    RealtimeTwistComponent,
    PrivacyComponent,
    PolicyModalComponent,
    FirmwaresComponent,
    DeleteModalComponent,
    WeightPipe,
    RealtimeChefComponent,
    ParameterCtrlComponent,
    ButtonsEditingParametersComponent,
    ParameterListComponent,
    OnOffPipe,
    ParameterLinearComponent,
    ParameterNonLinearComponent,
    ParameterComponent,
    ParametersDescrPipe,
    NotificationSettingsComponent,
    RecipeSoftHtFormComponent,
    HourMinutePipe
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    BrowserModule,
    ChartsModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    CommonModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
    }),
    MatMomentDateModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: MatPaginatorIntl, useClass: MatPaginationIntlService,
    },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {static: true}},
    TranslateService,
    DatePipe,
    HourMinutePipe
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    EditassociationModalComponent,
    LogoutModalComponent,
    AboutModalComponent,
    ForgotpasswordModalComponent,
    ModifyuserModalComponent,
    RecipeTwistFormComponent,

  ]
})
export class AppModule {
  constructor() {
  }
}
