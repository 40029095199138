import { Component, Input, OnInit } from "@angular/core";
import { Param } from "../objects/param";
import { ParameterService } from "../services/parameter.service";
import { SetpointParameterService } from "../services/setpoint-parameter.service";
import { IMqttMessage, MqttService } from "ngx-mqtt";
import { TranslateService } from "@ngx-translate/core";
import { SnackbarService } from "../services/snackbar.service";
import { SetSetpointParameters } from "../objects/setSetpointParameters";
import { ParElencoParametri } from "../objects/parElencoParametri";
import { timer } from "rxjs";
import { HourMinutePipe } from "../utils/pipes/hour-minute.pipe";

@Component({
  selector: "app-parameter-list",
  templateUrl: "./parameter-list.component.html",
  styleUrls: ["./parameter-list.component.scss"],
  providers: [HourMinutePipe]
})
export class ParameterListComponent implements OnInit {
  parametersList: Param[] = [];
  parElencoParList: ParElencoParametri[] = [];
  setpointList: SetSetpointParameters = null;
  realTimeEnableSectorKey = 'realTimeEnableSectorKey';
  showAlert=false;

  @Input() sector: number;
  @Input() modelloId: number;
  @Input() numeroSerie: number;
  loading: boolean;
  last_update: Date;
  showDate: boolean = false;
  machineConnected = false;
  constructor(
    private parameterService: ParameterService,
    private setpointService: SetpointParameterService,
    protected mqttService: MqttService,
    private snackBarService: SnackbarService,
    private translateService: TranslateService,
    protected hourMinutePipe: HourMinutePipe
  ) { }

  ngOnInit(): void {
    this.loading = true;

    this.getData(this.sector);

    const topicParameters = 'Frigomat/Parameters/' + this.numeroSerie;
    const topicRealTimeSubscribed = 'Frigomat/RealTime/' + this.numeroSerie;

    let time = timer(1000);

    let sub =time.subscribe(x => {
      this.parameterService.readCustom(this.modelloId).subscribe((resList) => {
        this.parElencoParList = resList;
        this.reloadList();
      });
    });

    
    this.mqttService.observe(topicParameters).subscribe((message: IMqttMessage) => {
      console.log('Arrived topic: PARAMETERS');
      sub.unsubscribe();
      this.machineConnected = true;
        this.parameterService.readCustom(this.modelloId).subscribe((resList) => {
          this.parElencoParList = resList;
          this.reloadList();
      });
    });

    this.mqttService.observe(topicRealTimeSubscribed).subscribe((message: IMqttMessage) => {
      console.log('Arrived topic: REALTIME');
      this.machineConnected = true;
      this.stopRealTime();
    });
  }



  reloadList() {
  this.setpointService
      .readCustom(this.numeroSerie, this.sector)
      .subscribe((setpointList) => {
        if(setpointList==null || setpointList.length==0)
        this.showAlert=true;
        this.setpointList = setpointList[0];
        this.parametersList = [];
        this.parElencoParList.sort((a, b) => a.paR_IdParametro - b.paR_IdParametro);
        if(setpointList[0].seT_LastUpdate!=null){
          this.last_update = new Date(setpointList[0].seT_LastUpdate + 'Z');
          this.showDate = true;
        }
        for (let parameter of this.parElencoParList) {
          
          var type = parameter.paR_Type
            ? parameter.paR_Type.split(".")
            : null;
          var value;
          var id = parameter.paR_IdParametro - 100;
          if (id == 0 && setpointList.length > 0) {
            value = setpointList[0]["seT_Par100"];
          } else if (id > 0 && setpointList.length > 0) {
            value = setpointList[0]["seT_Par" + id];
          } else {
            value = null;
          }
         
          if (value != null) {
            if(this.modelloId!=2 || this.sector%2!=0 || ![134,135,136,137].includes( parameter.paR_IdParametro )){
              this.parametersList.push({
                value: [value],
                id: parameter.paR_Id,
                default: parameter.paR_Default,
                description: parameter.paR_Descrizione,
                parameterUM: type && type.length > 0 ? type[0] : "",
                minValue: parameter.paR_Min,
                maxValue: parameter.paR_Max,
                ctrlType: parameter.paR_List
                  ? parameter.paR_List === "Off;On"
                    ? 1
                    : 2
                  : 0,
                step: parameter.paR_Step,
                multiplierValue: type && type.length > 1 ? +Math.pow(10, +type[1]) : 1,
                labels: parameter.paR_List ? parameter.paR_List.split(";") : [],
              });
            }
          }
        }
        this.loading = false;
      });
  }

  getData(sxdx: number) {
    console.log('-----------------------');
    console.log('Settore: ', sxdx);

    localStorage.setItem(this.realTimeEnableSectorKey, sxdx.toString());

    
   // const u8 = new Uint8Array([1, 0, 0, 0, sxdx, 0, 2, 0, 0, 1, 0, 0]);
    const u8 = new Uint8Array([1,1,0,0,sxdx,0,0,0,0,0,0,0]);
    var app=this;
    var app = this;
    this.sendPortal(u8, function () {
      //console.log('sended getData');
      console.log('sended startRealTime');
    }, function (err) { console.error(err) });
  }


  sendPortal(u8: Uint8Array, callbackFunction, callbackError) {
    const decoder = new TextDecoder('utf8');
    const b64encoded = btoa(decoder.decode(u8));

    this.mqttService.publish('Frigomat/Portal/' + this.numeroSerie, b64encoded)
      .subscribe(res => {
        callbackFunction();
      }, err => {
        callbackError(err);
      });
  }

  NewValue(param: Param) {
    console.log("param", param);
  }

  setMap(param: Param) {
    let idx = this.parametersList.findIndex((x) => {
      x.id == param.id;
    });
    this.parametersList[idx] = param;
  }

  convertUint16toByte(number) {
    // we want to represent the input as a 8-bytes array
    var byteArray = [0, 0];

    for (var index = 0; index < byteArray.length; index++) {
      var byte = number & 0xff;
      byteArray[index] = byte;
      number = (number - byte) / 256;
    }

    return byteArray;
  }

  save() {
    this.loading = true;

    console.log("------------------------");
    var arrInitialPart = [1, 0, 0, 0, this.sector, 0, 0, 2, 0, 1, 0, 200];

    var parametersArr = Array<number>(200).fill(0);
    this.parametersList.forEach((element) => {
      let idParam = Number(element.description.substring(1, 3));
      idParam = (idParam + 99) % 100;
      let valueArray = this.convertUint16toByte(element.value[0]);
      parametersArr[idParam * 2] = valueArray[1];
      parametersArr[idParam * 2 + 1] = valueArray[0];
      this.setpointList["seT_Par" + (idParam + 1)] = element.value[0];
    });
    let arr = arrInitialPart.concat(parametersArr);
    const u8 = new Uint8Array(arr);
    const b64encoded = btoa(String.fromCharCode.apply(null, u8));

    this.mqttService
      .publish("Frigomat/Portal/" + this.numeroSerie, b64encoded)
      .subscribe(
        (res) => {
          this.mqttService.observe("Frigomat/Return/" + this.numeroSerie).subscribe(() => {
            console.log("success");
            this.setpointService.write(this.setpointList).subscribe((x) => {
              this.snackBarService.showSnackBar(this.translateService.instant("operation_successfully"));
            },
              (err) => {
                console.error(err);
                this.snackBarService.showSnackBar(
                  this.translateService.instant("error") +
                  ":" +
                  this.translateService.instant(err.error)
                );
                this.loading = false;
              })

            this.loading = false;
          },
            (err) => {
              console.error(err);
              this.snackBarService.showSnackBar(
                this.translateService.instant("error") +
                ":" +
                this.translateService.instant(err.error)
              );
              this.loading = false;
            }
          );
        },
        (err) => {
          console.error(err);
          this.snackBarService.showSnackBar(
            this.translateService.instant("error") +
            ":" +
            this.translateService.instant(err.error)
          );
          this.loading = false;
        }
      );
  }

  stopRealTime() {
    console.log('------------------------');
    const u8 = new Uint8Array([1,0,0,0,0,0,0,0,0,0,0,0]);

    var app= this;
    this.sendPortal(u8,function(){
      console.log('sended stop realtime');
    },function(err){console.error(err)});
  }
}
