<button mat-raised-button color="warn" (click)="stopRealTime()" style="width: 100%;">Stop Real-Time</button>
<br /><br>
<b>Cuoci crema</b>
<mat-card class="mat-elevation-z8 realtime-container">
  <mat-card-title-group>
    <mat-card-subtitle>{{'booked_sector' | translate}}:</mat-card-subtitle>
    <mat-card-title
      *ngIf="(realTimeEnableSector === 0 || realTimeEnableSector === 49 || whatModelSxDxSelected !== 'sx')">
      {{'no_sector_booked'| translate}}
    </mat-card-title>
    <mat-card-title
      *ngIf="(realTimeEnableSector !== 0 && realTimeEnableSector !== 49 && whatModelSxDxSelected === 'sx')">
      1
    </mat-card-title>
  </mat-card-title-group>

  <mat-card-content>
    <div class="card-content">
      <mat-divider></mat-divider>
      <button mat-raised-button color="primary" (click)="startRealTimeSector(1, 'sx')" style="width: 100%; margin-top: 2%; margin-bottom: 2%;">{{'book_realtime'|
        translate}}</button>
      <div class="mat-card-container">
        <mat-label>
          {{'status'| translate}}:
          <mat-icon *ngIf="(realtimeObj.sx_dx === 1)" style="color: green;">wifi</mat-icon>
          <mat-icon *ngIf="(realtimeObj.sx_dx !== 1)" style="color: red;">wifi</mat-icon>
        </mat-label>
        <div class="states">
          {{'status'| translate}}
          <span *ngIf="(realtimeObj.sx_dx === 1)" [ngSwitch]="realtimeObj.stato">
            <div *ngSwitchCase="[1, 2, 4].includes(realtimeObj.stato) ? realtimeObj.stato : -1">
              <mat-icon>play_arrow</mat-icon>
            </div>
            <div *ngSwitchCase="[16, 32].includes(realtimeObj.stato) ? realtimeObj.stato : -1">
              <mat-icon class="rotate-8-s">refresh</mat-icon>
            </div>
            <div *ngSwitchCase="[8].includes(realtimeObj.stato) ? realtimeObj.stato : -1">
              <span>CN</span>
            </div>
            <div *ngSwitchCase="[0].includes(realtimeObj.stato) ? realtimeObj.stato : -1">
              <mat-icon>stop</mat-icon>
            </div>
            <div *ngSwitchDefault>
              <mat-icon>stop</mat-icon>
            </div>
          </span>
        </div>
        <div class="states">
          <mat-icon>notifications</mat-icon>
          {{'alarms_on_now' | translate}}
          <span>
            {{ (realtimeObj.allarmi>0 ? 'yes':'-') | translate | uppercase }}
          </span>
        </div>
        <div class="states">
          <mat-icon>thermostat</mat-icon>
          {{'tank_temperature' | translate}}
          <span>
            {{(realtimeObj.temp_vasca !== 150) ? realtimeObj.temp_vasca : '---'}}
          </span>
        </div>
        <div class="states">
          <mat-icon>thermostat</mat-icon>
          {{'fluid_temperature' | translate}}
          <span>
            {{(realtimeObj.temp_cts !== 150) ? realtimeObj.temp_cts : '---'}}
          </span>
        </div>
      </div>
      <div class="mat-card-container">
        {{'send_command' | translate | uppercase}}
        <button mat-raised-button color="primary" (click)="sendCommandRealTime(1, 4)">{{'start_conservation' | translate
          | uppercase}}</button>
        <button mat-raised-button color="warn" (click)="sendCommandRealTime(1, 100)">{{'stop_sector' | translate |
          uppercase}}</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br />
<b>Mantecatore</b>
<mat-card class="mat-elevation-z8 realtime-container">
  <mat-card-title-group>
    <mat-card-subtitle>{{'booked_sector' | translate}}:</mat-card-subtitle>
    <mat-card-title
      *ngIf="(realTimeEnableSector === 0 || realTimeEnableSector === 49 || whatModelSxDxSelected !== 'dx')">
      {{'no_sector_booked'| translate}}
    </mat-card-title>
    <mat-card-title
      *ngIf="(realTimeEnableSector !== 0 && realTimeEnableSector !== 49 && whatModelSxDxSelected === 'dx')">
      2
    </mat-card-title>
  </mat-card-title-group>

  <mat-card-content>
    <div class="card-content">
      <mat-divider></mat-divider>
      <button mat-raised-button color="primary" (click)="startRealTimeSector(2, 'dx')" style="width: 100%; margin-top: 2%; margin-bottom: 2%;">{{'book_realtime'|
        translate}}</button>
      <div class="mat-card-container">
        <mat-label>
          {{'status'| translate}}: <mat-icon *ngIf="(realtimeObjAd.sx_dx === 2)"
            style="color: green;">wifi</mat-icon><mat-icon *ngIf="(realtimeObjAd.sx_dx !== 2)"
            style="color: red;">wifi</mat-icon>
        </mat-label>

        <div class="states">
          {{'status'| translate}}
          <span *ngIf="(realtimeObjAd.sx_dx === 2)" [ngSwitch]="realtimeObjAd.stato">
            <div *ngSwitchCase="[1, 2, 4].includes(realtimeObjAd.stato) ? realtimeObjAd.stato : -1">
              <mat-icon>play_arrow</mat-icon>
            </div>
            <div *ngSwitchCase="[16, 32, 128].includes(realtimeObjAd.stato) ? realtimeObjAd.stato : -1">
              <mat-icon class="rotate-8-s">refresh</mat-icon>
            </div>
            <div *ngSwitchCase="[0].includes(realtimeObjAd.stato) ? realtimeObjAd.stato : -1">
              <mat-icon>stop</mat-icon>
            </div>
            <div *ngSwitchDefault>
              <mat-icon>stop</mat-icon>
            </div>
          </span>
        </div>
        <div class="states">
          <div><mat-icon>notifications</mat-icon> {{'alarms_on_now' | translate}}</div>
          <span *ngIf="(realtimeObjAd.allarmi > 0)">{{'yes' | translate | uppercase}}</span>
          <span *ngIf="(realtimeObjAd.allarmi < 0)">{{'no' | translate | uppercase}}</span>
        </div>
        <div class="states">
          <div> {{'consistency' | translate}}</div>
          <span>{{(realtimeObjAd.consistenza < 251) ? realtimeObjAd.temp_cts : (whatModelSxDxSelected==='dx' &&
              realtimeObjAd.sx_dx===2) ? '---' : '' }}</span>
        </div>
      </div>
      <div class="mat-card-container">
        {{'send_command' | translate | uppercase}}
        <button mat-raised-button color="warn" (click)="sendCommandRealTime(2, 100)">{{'stop_sector' | translate |
          uppercase}}</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
