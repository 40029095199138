import { Pipe, PipeTransform } from '@angular/core';
import { UteUser } from 'src/app/objects/uteUser';

@Pipe({
  name: 'userInRole'
})
export class UserInRolePipe implements PipeTransform {

  transform(userList: UteUser[], level: number[]): any {
    return userList.filter(user => {return level.findIndex((x)=>{return x===user.utE_Livello})> -1});
  }
}
