import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MacMacchine } from '../objects/macMacchine';
import { AppConfigService } from './app-config.service';
import { GenericCrudService } from './generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class MachineService extends GenericCrudService<MacMacchine,number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
   super(http ,appConfigService,"machine");
   this.load();
 }


 get(serialMs:number): Observable<MacMacchine> {
  console.log(this.apiName);
  return this.http.get<MacMacchine>(`${this.endpoint}/${this.apiName}/serialMs=${serialMs}`);
}


}
