

import { IModelObj } from "./iModelObj";

export interface IAumAssociationUserMachine extends IModelObj {
    dealerId: number;
    customerId: number;
    listTechId: number[];
    maC_Numero_Serie_MS: number;
    maC_Matricola: string;
    maC_Macchina: number;
    maC_LastUpdate: Date;
    maC_Pagamento: number;
    maC_Grammi: number;
    maC_Indirizzo: string;
}

export class AumAssociationUserMachine implements IAumAssociationUserMachine{
    id: string | number;
    dealerId: number;
    customerId: number;
    listTechId: number[];
    maC_Numero_Serie_MS: number;
    maC_Matricola: string;
    maC_Macchina: number;
    maC_LastUpdate: Date;
    maC_Pagamento: number;
    maC_Grammi: number;
    maC_Indirizzo: string;
    maC_BloccoSx:number;
    maC_BloccoDx:number;
    maC_VersSwInt:string;
    maC_VersSwQSx:string;
    maC_VersSwVSx:string;
    maC_ModelloSx:number;
    maC_VersSwQDx:string;
    maC_VersSwVDx:string;
    maC_ModelloDx:number;

    constructor(props?: IAumAssociationUserMachine) {
        if(props!=undefined){
            Object.keys(props).forEach(prop => {
                const value = props[prop];
                this[prop] = value;
            });
        }
        this.id = props && props.maC_Numero_Serie_MS || 0;
    }

    
    
}