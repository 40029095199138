<mat-card class="mat-elevation-z8">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div>
    <h3>{{ 'analysis' | translate }}</h3>
  </div>
  <div>
    <mat-radio-group>
      <mat-radio-button style="margin-left: 2.5%;" *ngFor="let item of optionsFinder" (click)="showPie(item)"
        [checked]="(item === '3M')" value="{{item}}">{{ item | translate }}</mat-radio-button>
    </mat-radio-group>
    <br><br>
  </div>

  <h4 *ngIf="pieCharts.length < 1">{{ 'no_data_interval' | translate }}</h4>
  <div *ngFor="let pie of pieCharts;let i =index ">
    <h4 *ngIf="model.length>1">{{model[i].moD_Descrizione}} </h4>
    <canvas height="90%" baseChart [data]="pie.data" [labels]="pie.labels" chartType="pie" legend="true"
      [colors]="pie.colors"></canvas>
    <br />
  </div>
</mat-card>
<br />
<mat-accordion>
  <mat-expansion-panel class="mat-elevation-z8">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4>{{ 'productionList' | translate }} </h4>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <table mat-table [dataSource]="dataSourceProduction" matSort>
      <ng-container matColumnDef="scO_Ricetta">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'name' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{ element |
          flavourName:flavoursArray[0].flvList:flavoursArray[flavoursArray.length-1].flvList:modelListCod }} </td>
      </ng-container>
      <ng-container matColumnDef="scO_Peso">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'weight' | translate}} </th>
        <td mat-cell *matCellDef="let element">
          <div>{{element | weight:model}} </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="scO_SxDx">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'sector' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.scO_SxDx }} </td>
      </ng-container>

      <ng-container matColumnDef="scO_DateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'datetime' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.scO_DateTime | date:'dd/MM/yyyy HH:mm'}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 50, 500, 1000]" showFirstLastButtons></mat-paginator>
  </mat-expansion-panel>
</mat-accordion>
