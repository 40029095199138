import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { LoginGuard } from './utils/guards/login.guard';
import { AlarmsComponent } from './alarms/alarms.component';
import { CommandsComponent } from './commands/commands.component';
import { ModifyComponent } from './modify/modify.component';
import { UsersComponent } from './users/users.component';
import { AssociationsComponent } from './associations/associations.component';
import { RealtimeComponent } from './realtime/realtime.component';
import { SingleunitComponent } from './singleunit/singleunit.component';
import { SingleunitGuard } from './utils/guards/singleunit.guard';
import { FlavoursComponent } from './flavours/flavours.component';
import { CountersComponent } from './counters/counters.component';
import { HeatrreatmentsComponent } from './heatrreatments/heatrreatments.component';
import { AnalysisGuard } from './utils/guards/analysis.guard';
import { AnalysisComponent } from './analysis/analysis.component';
import { AdminGuard } from './utils/guards/admin.guard';
import { RecipeBookComponent } from './recipe-book/recipe-book.component';
import { MachineSelectedGuard } from './utils/guards/machine-selected.guard';
import { MachineFlavourGuard } from './utils/guards/machine-flavour.guard';
import { MachineTouchGuard } from './utils/guards/machine-touch.guard';
import { PrivacyComponent } from './privacy/privacy.component';
import { FirmwaresComponent } from './firmwares/firmwares.component';
import { ParameterListComponent } from './parameter-list/parameter-list.component';
import { ParameterLinearComponent } from './parameter-linear/parameter-linear.component';
import { ParameterComponent } from './parameter/parameter.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';

const routes: Routes = [
  { path: 'privacy', component: PrivacyComponent},
  { path: 'dashboard', component: DashboardComponent,canActivate:[LoginGuard]},
  { path: 'login', component: LoginComponent },
  { path: 'alarms', component: AlarmsComponent,canActivate:[LoginGuard,MachineSelectedGuard] },
  { path: 'commands', component: CommandsComponent,canActivate:[LoginGuard,MachineSelectedGuard] },
  { path: 'modify', component: ModifyComponent,canActivate:[LoginGuard,MachineSelectedGuard]},
  { path: 'users', component: UsersComponent, canActivate: [LoginGuard,AdminGuard] },
  { path: 'associations', component: AssociationsComponent,canActivate: [LoginGuard,AdminGuard] },
  { path: 'realtime', component: RealtimeComponent,canActivate: [LoginGuard,MachineSelectedGuard] },
  { path: 'singleunit', component: SingleunitComponent, canActivate: [LoginGuard,SingleunitGuard] },
  { path: 'flavours', component: FlavoursComponent,canActivate:[LoginGuard,MachineSelectedGuard,MachineFlavourGuard] },
  { path: 'recipes', component: RecipeBookComponent,canActivate:[LoginGuard,MachineSelectedGuard,MachineTouchGuard] },
  { path: 'counters', component: CountersComponent, canActivate:[LoginGuard,SingleunitGuard]},
  { path: 'analysis', component: AnalysisComponent, canActivate: [LoginGuard,SingleunitGuard,AnalysisGuard]},
  { path: 'heat', component: HeatrreatmentsComponent },
  { path: 'firmwares', component: FirmwaresComponent, canActivate:[LoginGuard,AdminGuard]},
  { path: 'parameters', component: ParameterComponent, canActivate:[LoginGuard,MachineSelectedGuard]},
  { path: 'parameters-linear', component: ParameterLinearComponent, canActivate:[LoginGuard,MachineSelectedGuard]},
  { path: 'notification-settings', component: NotificationSettingsComponent, canActivate:[LoginGuard]},
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
