import { Pipe, PipeTransform } from '@angular/core';
import { UteUser } from 'src/app/objects/uteUser';

@Pipe({
  name: 'user'
})
export class UserPipe implements PipeTransform {

  transform(userList: UteUser[], id_user: number): any {
    return  userList.find((user) => {return user.utE_Id === id_user});
  }

}
