import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CmdElencoComandi } from '../objects/cmdElencoComandi';
import { AppConfigService } from './app-config.service';
import { GenericCrudService } from './generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class CommandService  extends GenericCrudService<CmdElencoComandi,number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
   super(http ,appConfigService,"CommandsList");
   this.load();
  }
  
}
