<mat-card class="mat-elevation-z8 realtime-container">

  <div class="card-content" *ngFor="let realtime of realtimeList;index as i">
    <div class="mat-card-container">
      <mat-label>
        {{'sector' | translate}}:
        <label>{{realtime[0]}}<br></label>
        {{'status' | translate}}: <mat-icon
          [ngStyle]="{'color': realtime[2] || realtime[1]?'green':'red'}">wifi</mat-icon></mat-label>
      <div class="states">
        {{'status' | translate}}
        <span>
          <mat-icon [ngClass]="{'rotate-8-s': realtime[2]?.barrelBeater.active , 'blink-red-1-s':realtime[2]?.heatCycle?.active  }">{{realtime[2] |
            softTouchStatusIcon}}</mat-icon>
        </span>
      </div>
      <div class="states">
        <div><mat-icon>notifications</mat-icon> {{'alarms_on_now' | translate}}</div>
        <span *ngIf="realtime[2]">{{ (realtime[2]?.alarmsActive? 'yes':'-') | translate | uppercase }}
        </span>
      </div>
      <div class="states" *ngIf="realtime[3]==3  || realtime[3]==4">
        <div><mat-icon> thermostat</mat-icon> {{'tank_temperature' | translate}}</div>
        {{realtime[1]?.temperature!=undefined? realtime[1]?.temperature: realtime[1]?.tankTemperature }} °C
      </div>
      <div class="states" *ngIf="(realtime[3]==3  || realtime[3]==4) && realtime[1]?.barrelTemperature!=undefined">
        <div><mat-icon> thermostat</mat-icon> {{'barrel_temperature' | translate}}</div>
        {{realtime[1]?.barrelTemperature}} °C
      </div>
    </div>
    <div class="mat-card-container">
      {{'send_command' | translate }}
      <button mat-raised-button color="primary" (click)="startConservation(realtime[0])">{{'start_conservation' |
        translate | uppercase }}</button>
      <button mat-raised-button color="warn" (click)="stop(realtime[0])"> STOP</button>
    </div>
    <div *ngIf="i !== realtimeList.length - 1">
      <br>
      <mat-divider></mat-divider>
    </div>
  </div>
</mat-card>
