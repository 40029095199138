import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private snackBar: MatSnackBar,
    private translationService: TranslateService
  ) { }

  showSnackBar(message: string): void {

    let closeTranslated;

    this.translationService.get("close")
    .subscribe(res => {
      // console.log(res);
      closeTranslated = res;
    });

    this.snackBar.open(message, closeTranslated,
      { 
        duration: 2500
      }
    );
  }

  showErrorBar(message: string){
    this.showCustomBar(message,'error-alert-class')
  }
  showWarningBar(message: string){
    this.showCustomBar(message,'warning-alert-class')
  }
  showSuccessBar(message: string){
    this.showCustomBar(message,'success-alert-class')
  }
  
  private showCustomBar(message: string,panelClass): void {

    let closeTranslated;

    this.translationService.get("close")
    .subscribe(res => {
      // console.log(res);
      closeTranslated = res;
    });

    this.snackBar.open(message, closeTranslated,
      { 
        duration: 2500,
        panelClass:[panelClass]
      }
    );
  }
}
