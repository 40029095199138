import { Component } from '@angular/core';
import {MqttService } from 'ngx-mqtt';
import { RlRealTime } from 'src/app/objects/rlRealTime';
import { CommandService } from 'src/app/services/command.service';
import { SessionService } from 'src/app/services/session.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { RealtimeLcdComponent } from '../realtime-lcd/realtime-lcd.component';


@Component({
  selector: 'app-realtime-twinchef',
  templateUrl: './realtime-twinchef.component.html',
  styleUrls: ['./realtime-twinchef.component.scss']
})
export class RealtimeTwinchefComponent extends RealtimeLcdComponent {

  realtimeObjAd: RlRealTime = new RlRealTime();
  whatModelSxDxSelected;

  constructor(
    protected mqttService: MqttService,
    protected sessionService: SessionService,
    protected snackBarService: SnackbarService,
    protected commandService:CommandService
    ) {
      super(mqttService ,sessionService,snackBarService,commandService);
    }

    startRealTimeSector(sxdx: number, whatModelSelected: string) {

      console.log('-----------------------');
      console.log('Settore: ', sxdx);
      this.whatModelSxDxSelected = whatModelSelected;
      console.log('WhatModelSelected: ', this.whatModelSxDxSelected);
  
      // reset modelli a schermo
      if(this.whatModelSxDxSelected === 'sx'){
        this.realtimeObjAd = new RlRealTime();
      } else {
        this.realtimeObj = new RlRealTime();
      }
  
      localStorage.setItem(this.realTimeEnableSectorKey, sxdx.toString());
  
      super.startRealTime(sxdx);// this.sendResponse();
    }

    getResponseMQTT(msg: string) {

      console.log('TMP: ', msg);
  
      try {
        if(this.whatModelSxDxSelected === 'sx'){

          this.realtimeObjAd = new RlRealTime();
          super.getResponseMQTT(msg);
        }
  
        if(this.whatModelSxDxSelected === 'dx'){
          const translated = this.base64toHEX(msg);
          console.log('TRANSLATED: ', translated);
  
          this.realtimeObjAd = new RlRealTime();
  
          this.realtimeObjAd.sx_dx = parseInt(translated.substr(0, 2), 16);
          this.realtimeObjAd.stato = parseInt(translated.substr(2, 2), 16);
          this.realtimeObjAd.allarmi = parseInt(translated.substr(10, 10), 16);
          this.realtimeObjAd.tensione = parseInt(translated.substr(20, 4), 16);
          this.realtimeObjAd.assorbimento = parseInt(translated.substr(24, 4), 16);
          this.realtimeObjAd.sfasamento = parseInt(translated.substr(28, 4), 16);
          this.realtimeObjAd.potenza = parseInt(translated.substr(32, 4), 16);
          this.realtimeObjAd.consistenza = parseInt(translated.substr(36, 4), 16);
          this.realtimeObjAd.temp_vasca = this.applyConversion(parseInt(translated.substr(40, 4), 16)); // FFA7 | -8.9 convert to unsegned
          this.realtimeObjAd.temp_cts = this.applyConversion(parseInt(translated.substr(44, 4), 16)); // FFC0 | -
          this.realtimeObjAd.pt100_3 = parseInt(translated.substr(48, 4), 16);
          this.realtimeObjAd.lettco_1 = parseInt(translated.substr(52, 4), 16);
          this.realtimeObjAd.lettco_2 = parseInt(translated.substr(56, 4), 16);
          this.realtimeObjAd.ingressi = parseInt(translated.substr(60, 2), 16);
          this.realtimeObjAd.uscite = parseInt(translated.substr(62, 2), 16);
  
          this.realTimeEnableSector = this.realtimeObjAd.sx_dx;
  
          if (this.realtimeObjAd.sx_dx !== 49 && this.realtimeObjAd.sx_dx !== 0) {
            this.startRealTimeSector(this.realtimeObjAd.sx_dx, this.whatModelSxDxSelected);
          }
  
          console.log('REALTIME OBJ: ', this.realtimeObjAd);
          console.log('REALTIME OBJ Received ModelType: ', this.whatModelSxDxSelected);
        }
  
      } catch (error) {
  
      }
      console.log('From Base64');
    }

    stopRealTime() {

      console.log('------------------------');
      const u8 = new Uint8Array([1,0,0,0,0,0,0,0,0,0,0,0]);
      var app=this;
      this.sendPortal(u8,function(){
        app.realTimeEnableSector = 0;
        app.realtimeObj.sx_dx = 0;
        app.realtimeObj.temp_vasca = undefined;
        app.realtimeObj.temp_cts = undefined;
  
        app.realtimeObjAd.sx_dx = 0;
        app.realtimeObjAd.temp_vasca = undefined;
        app.realtimeObjAd.temp_cts = undefined;
        app.realtimeObjAd.consistenza = undefined;
  
        console.log('sended stop realtime');
      },function(err){console.error(err)});
      
    }
}