
<!-- valore numerico editabile -->
<div class="element" *ngIf="ctrlType==0 && show">
    <mat-form-field   *ngFor="let val of value;index as i">
        <mat-label >{{description | parametersDescr:i:value.length  }}</mat-label>
        <div  [ngSwitch]="isEditable">
            <span  *ngSwitchCase="true">
                <div [ngSwitch]="parameterUM">
                    <div *ngSwitchCase="'time'" >
                        <input [disabled]="true" #ctrlId  matInput type="time" value="{{val | hourMinute}}" >
                        <app-buttons-editing-parameters type="String" [initialVal]="default" [idx]="i" [show]="isEditable" [ctrl]="ctrlId" [step]="param.step" [multiplierValue]="multiplierValue"  (newValue)="NewValue($event,i)" [displayPipe]="hourMinutePipe"></app-buttons-editing-parameters>
                    </div>
                    <div *ngSwitchDefault>
                        <input  min="{{minValue}}" max="{{maxValue}}" step="{{step}}" [disabled]="true" #ctrlId  matInput type="number" [ngModel]="val" >
                        <mat-label >{{parameterUM}}</mat-label>
                        <app-buttons-editing-parameters [initialVal]="default" [idx]="i" [show]="isEditable" [ctrl]="ctrlId" [step]="param.step" [multiplierValue]="multiplierValue" (newValue)="NewValue($event,i)" [displayPipe]="displayPipe" [displayPipeParam]="displayPipeParam"></app-buttons-editing-parameters>
                    </div>
                </div>
            </span>
            <span *ngSwitchCase="false">
                <input  [disabled]="true" matInput  [ngModel]="val">
                <mat-label>{{parameterUM=="time"?"HH:MM":parameterUM}}</mat-label>
            </span>
        </div>  
    </mat-form-field>
</div>

<!-- valore on/off -->
<div class="element" *ngIf="ctrlType==1 && show">
    <div *ngFor="let val of value;index as i">
        <mat-label class="define-attribute">{{description | parametersDescr:i:value.length }}</mat-label>
        <div [ngSwitch]="isEditable">
            <span *ngSwitchCase="true">
                <mat-slide-toggle [disabled]="true" #ctrlId  color="primary" [checked]="GetBooleanValue(val)" (click)="$event.stopPropagation()"></mat-slide-toggle>
                <mat-label>{{ctrlId.checked | oNoFF}}</mat-label>
                <app-buttons-editing-parameters min-width="100px" [initialVal]="default" [idx]="i" [show]="isEditable" [ctrl]="ctrlId" [multiplierValue]="multiplierValue" [step]="step"  (newValue)="NewValue($event,i)"></app-buttons-editing-parameters>
            </span>
            <span *ngSwitchCase="false">
                <mat-slide-toggle [disabled]="true" #ctrlId color="primary" [checked]="val==1"></mat-slide-toggle>
                <mat-label>{{val==1 | oNoFF}}</mat-label>
            </span>
        </div>
    </div>    
</div>

<!-- select -->
<div class="element" *ngIf="ctrlType==2 && show" >
    <mat-form-field *ngFor="let val of value;index as i">
        <mat-label >{{description | parametersDescr:i:value.length }}</mat-label>
        <div [ngSwitch]="isEditable">
            <span *ngSwitchCase="true">
                <mat-select  [disabled]="true" #ctrlId  [value]="val" >
                    <mat-option *ngFor="let item of labels" [value]="GetLabelIndex(item)+minValue">{{item | translate}}</mat-option>
                </mat-select>
                <mat-label class="input-number-label">{{parameterUM}}</mat-label>
                <app-buttons-editing-parameters [initialVal]="default" min-width="100px" [idx]="i" [show]="isEditable" [ctrl]="ctrlId"  [multiplierValue]="multiplierValue" (newValue)="NewValue($event,i)"></app-buttons-editing-parameters>
            </span>
            <span *ngSwitchCase="false">
                <mat-select class="input-select" [disabled]="true" #ctrlId [value]="val">
                    <mat-option *ngFor="let item of labels" [value]="GetLabelIndex(item)+minValue">{{item | translate}}</mat-option>
                </mat-select>
                <mat-label class="input-number-label">{{parameterUM}}</mat-label>
            </span>
        </div>
    </mat-form-field>
</div>




