import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { UteUser } from 'src/app/objects/uteUser';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserRole } from 'src/app/utils/enums/userRole';

@Component({
  selector: 'app-modifyuser-modal',
  templateUrl: './modifyuser-modal.component.html',
  styleUrls: ['./modifyuser-modal.component.scss'],
})
export class ModifyuserModalComponent implements OnInit {

  // @Input() data: UteUser;
  tmpUser: UteUser;
  formEdit: FormGroup;
  //tmpUserToChiudi: UteUser = new UteUser();

  constructor(
    private translateService:TranslateService,
    public dialogRef: MatDialogRef<ModifyuserModalComponent>,
    private snackBarService: SnackbarService,
    private loadingService: LoaderService,
    private userService:UserService,
    @Inject(MAT_DIALOG_DATA) public data: UteUser,
    protected fb: FormBuilder
  ) {
  }

  public get userRole(): typeof UserRole {
    return UserRole;
  }

  ngOnInit() {
    this.tmpUser = this.data;

    this.formEdit = this.fb.group({
      ute_nameCtrl: [this.tmpUser.utE_Nome,Validators.required],
      ute_descriptionCtrl: this.tmpUser.utE_Descrizione,
      ute_enabledCtrl:[this.tmpUser.utE_Abilitato,Validators.required],
      ute_levelCtrl:[this.tmpUser.utE_Livello,Validators.required],
      });
  }

  closeDialog()
  {
    this.dialogRef.close(false);
  }

  save() {
    var user: UteUser = {
      id:this.tmpUser.utE_Id,
      utE_Id:this.tmpUser.utE_Id,
      utE_Email1:undefined,
      utE_Sede:undefined,
      utE_Telefono1:undefined,
      utE_Nome: this.formEdit.get('ute_nameCtrl').value,
      utE_Descrizione: this.formEdit.get('ute_descriptionCtrl').value,
      utE_Abilitato: this.formEdit.get('ute_enabledCtrl').value,
      utE_Livello: this.formEdit.get('ute_levelCtrl').value,
      utE_PartitaIva:undefined,
      utE_Password:undefined,
      utE_Informativa: this.tmpUser.utE_Informativa
     };

    this.userService.update(user).subscribe(res => {
          this.snackBarService.showSnackBar(this.translateService.instant('operation_successfully'));
          this.dialogRef.close(true);
      },err => {
        console.log('Error add user: ', err.error);
      });
  }
}
