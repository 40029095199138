import { Component, ElementRef, EventEmitter, Input, OnInit, Output, PipeTransform, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PipeUnTransform } from '../utils/pipes/pipe-untransform';
import { Param } from '../objects/param';
import { HourMinutePipe } from '../utils/pipes/hour-minute.pipe';

@Component({
  selector: 'app-parameter-ctrl',
  templateUrl: './parameter-ctrl.component.html',
  styleUrls: ['./parameter-ctrl.component.scss'],
})
export class ParameterCtrlComponent implements OnInit {

  @Input() param: Param;
  @Input() isEditable: boolean = false;
  @Output() newValue = new EventEmitter();
  
  value: number[]=[];
  id: number = 0;
  default: number = 0;
  description: string = '';

  parameterUM: string = '';
  minValue: number = 0;
  maxValue: number = 0;
  ctrlType: number = 0;
  step: number = 1;
  multiplierValue: number = 1;
  labels: string[];

  show: boolean = false;
  additionalClass: string = '';
  updateValue: boolean = true;
  @Input() displayPipe:PipeUnTransform=undefined;
  @Input() displayPipeParam:any;

  constructor(
    protected translateService: TranslateService,
    protected hourMinutePipe: HourMinutePipe
  ) { }

  ngOnInit() {
      this.param.value.forEach(element => {
        this.value.push(element*this.param.step/this.param.multiplierValue);
      });
    this.id=this.param.id;
    this.default=this.param.default*this.param.step/this.param.multiplierValue;
    this.description=this.param.description;
    this.parameterUM=this.param.parameterUM!=null && this.param.parameterUM!=undefined && this.param.parameterUM!=="numeric" && this.param.parameterUM!=="list"?this.param.parameterUM:"";
    this.minValue=this.param.minValue*this.param.step/this.param.multiplierValue;
    this.maxValue=this.param.maxValue*this.param.step/this.param.multiplierValue;
    this.ctrlType=this.param.ctrlType;
    this.step=this.param.step/this.param.multiplierValue;
    this.multiplierValue=this.param.multiplierValue;
    this.labels=this.param.labels;

   this.show = this.value != undefined;
    if (!this.parameterUM || this.parameterUM.length === 0) {
      this.additionalClass = 'no-UM';
    }
  }


  multiplierValueToPrecision() {
    return Math.log10(this.multiplierValue);
  }

  ConvertNumberToLabel(value: any) {
   //console.log(value);
    if (value === 'true') {
      value = 1;
    }
    else if (value === 'false') {
      value = 0;
    }

    
      if(value>this.labels.length-1)
        return value;
      return this.translateService.instant( this.labels[value]);
  }

  GetLabelIndex(item: string) {
    return this.labels.findIndex(x => x === item);
  }

  GetBooleanValue(val) {
    this.updateValue = false;
    return (val == 1);
  }

  GetValue() {
    this.updateValue = false;
    if(this.displayPipe!=undefined){
      return this.displayPipe.transform((this.value),this.displayPipeParam);
    }
    this.updateValue = false;
    // tolgo eventuali caratteri che non sono munerici all'interno del valore
    return this.value;
  }

  NewValue(newValue: any) {
    if (newValue != undefined) {
      this.param.value[newValue.index] = newValue.value*1;
      this.value[newValue.index]=Number((this.param.value[newValue.index]/this.param.multiplierValue*this.param.step).toFixed(this.multiplierValueToPrecision()));
    }
    this.updateValue = true;
    this.newValue.emit(this.param);
  }

  changeVal(newValue: any,idx:number){
    this.value[idx]=newValue;
    console.log('aaa')
  }
}
