import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Recipe, RecipeSoft } from 'src/app/objects/recipe';
import { AwsIotProviderService } from 'src/app/services/aws-iot-provider.service';
import { ModelService } from 'src/app/services/model.service';
import { SessionService } from 'src/app/services/session.service';
import { FrigomatTopicsPub } from 'src/app/utils/enums/frigomatTopics';
import { RecipeFormComponent } from '../recipe-form';

@Component({
  selector: 'app-recipe-soft-form',
  templateUrl: './recipe-soft-form.component.html',
  styleUrls: ['./recipe-soft-form.component.scss']
})
export class RecipeSoftFormComponent extends RecipeFormComponent<RecipeSoft> {

  @Input() entity:RecipeSoft;
  @Input() entityList:RecipeSoft[];
  @Output() protected onFormChange = new EventEmitter<any>();
 
  
  constructor(
    protected fb: FormBuilder,
    protected translateService:TranslateService,
    protected awsIotProviderService:AwsIotProviderService,
    protected sessionService:SessionService,
    protected modelService:ModelService
  ) { 
    super(fb,awsIotProviderService, sessionService, modelService);
  }

  ngOnInit() {
    this.isNew = this.entity==null;
    this.entityTemp = new RecipeSoft();
    Object.assign(this.entityTemp,this.entity);
  
    this.form = this.fb.group({
      recipe_nameCtrl: [this.isNew?'':this.entity.name, Validators.compose([Validators.required,  (c)=> this.newRecipeValidator(c,this.entityList,this.entity)])],
      recipe_consistencyCtrl: [this.isNew || this.entity.consistency==null?RecipeSoft.ConsistencyMin:this.entity.consistency,Validators.compose([Validators.required,Validators.max(RecipeSoft.ConsistencyMax),Validators.min(RecipeSoft.ConsistencyMin)])],
      recipe_conservationOffCtrl: [this.isNew || this.entity.conservationOff==null?RecipeSoft.OnOffMin:this.entity.conservationOff,Validators.compose([Validators.required,Validators.max(RecipeSoft.OnOffMax),Validators.min(RecipeSoft.OnOffMin)])],
      recipe_conservationOnCtrl: [this.isNew || this.entity.conservationOn==null?RecipeSoft.OnOffMin:this.entity.conservationOn,Validators.compose([Validators.required,Validators.max(RecipeSoft.OnOffMax),Validators.min(RecipeSoft.OnOffMin)])],
      recipe_productionOffCtrl: [this.isNew || this.entity.productionOff==null?RecipeSoft.OnOffMin:this.entity.productionOff,Validators.compose([Validators.required,Validators.max(RecipeSoft.OnOffMax),Validators.min(RecipeSoft.OnOffMin)])],
      recipe_productionOnCtrl: [this.isNew || this.entity.productionOn==null?RecipeSoft.OnOffMin:this.entity.productionOn,Validators.compose([Validators.required,Validators.max(RecipeSoft.OnOffMax),Validators.min(RecipeSoft.OnOffMin)])],
      recipe_tankTempCtrl: [this.isNew || this.entity.tankTemperature==null?RecipeSoft.TankTempMin:this.entity.tankTemperature,Validators.compose([Validators.required,Validators.max(RecipeSoft.TankTempMax),Validators.min(RecipeSoft.TankTempMin)])],
      recipe_creativeCtrl: [this.isNew || this.entity.creative==null?false:this.entity.creative,Validators.required],
      recipe_idCtrl: this.isNew?'':this.entity.id,
      });

      this.form.valueChanges
      .subscribe(() =>{ 
        this.onFormChange.emit(this.form.value)
      });
      this.onFormChange.emit(this.form.value);
  }

  public get OnOffMin(){
    return RecipeSoft.OnOffMin;
  }

  public get OnOffMax(){
    return RecipeSoft.OnOffMax;
  }

  public get TankTempMin(){
    return RecipeSoft.TankTempMin;
  }

  public get TankTempMax(){
    return RecipeSoft.TankTempMax;
  }

  update(isNew:boolean=false){
    try{
    let recipe = new RecipeSoft();
    recipe.id=isNew?undefined:this.form.get('recipe_idCtrl').value;
    recipe.name=this.form.get('recipe_nameCtrl').value;
    recipe.consistency= this.form.get('recipe_consistencyCtrl').value;
    recipe.conservationOff= this.form.get('recipe_conservationOffCtrl').value;
    recipe.conservationOn= this.form.get('recipe_conservationOnCtrl').value;
    recipe.productionOff= this.form.get('recipe_productionOffCtrl').value;
    recipe.productionOn= this.form.get('recipe_productionOnCtrl').value;
    recipe.creative= this.form.get('recipe_creativeCtrl').value;
    recipe.tankTemperature= this.form.get('recipe_tankTempCtrl').value;
  
    this.publish(isNew?FrigomatTopicsPub.addRecipe:FrigomatTopicsPub.editRecipe,recipe);
      return true;
    }
    catch{
      return false;
    }
  }

  delete(){
    try{
    let recipe = new RecipeSoft();
    recipe.id=this.form.get('recipe_idCtrl').value;
    recipe.name=this.form.get('recipe_nameCtrl').value;
    recipe.consistency= this.form.get('recipe_consistencyCtrl').value;
    recipe.conservationOff= this.form.get('recipe_conservationOffCtrl').value;
    recipe.conservationOn= this.form.get('recipe_conservationOffCtrl').value;
    recipe.productionOff= this.form.get('recipe_productionOffCtrl').value;
    recipe.productionOn= this.form.get('recipe_productionOnCtrl').value;
    recipe.creative= this.form.get('recipe_tankTempCtrl').value;
    recipe.tankTemperature= this.form.get('recipe_creativeCtrl').value;
  
    this.publish(FrigomatTopicsPub.deleteRecipe,recipe);
      return true;
    }
    catch{
      return false;
    }
  }
}
