import { Pipe, PipeTransform } from '@angular/core';
import { MacMacchine } from 'src/app/objects/macMacchine';
import { ModElencoModelli } from 'src/app/objects/modElencoModelli';
import { ScoStoricoComandi } from 'src/app/objects/scoStoricoComandi';
import { SmoStoricoModifiche } from 'src/app/objects/smoStoricoModifiche';
import { SessionService } from 'src/app/services/session.service';
import { ConfigUtil } from '../configUtils';

@Pipe({
  name: 'weight'
})
export class WeightPipe implements PipeTransform {


  machineSessioned: MacMacchine = new MacMacchine();
  constructor(protected sessionService: SessionService
    ) {
    this.machineSessioned = JSON.parse(this.sessionService.getGenericSession(ConfigUtil.machineSessioned));
  }


  transform(value: ScoStoricoComandi,modelsList:ModElencoModelli[]): any {
    let idToPass = this.machineSessioned.maC_ModelloSx;
      // solo la GX è diversa DA tutte le altre, la filtro nell'if

      if(this.machineSessioned.maC_ModelloSx !== 2){
        // tytte le altre macchine
        idToPass = 0;
        if(value.scO_SxDx === 1){ idToPass = this.machineSessioned.maC_ModelloSx; }
        if(value.scO_SxDx === 2){ idToPass = this.machineSessioned.maC_ModelloDx; }
        if(value.scO_SxDx === 3){ idToPass = this.machineSessioned.maC_ModelloCen; }
      }
      let model = modelsList.find(x =>  x.moD_Codice === idToPass);

      let result= value.scO_Peso/model.moD_Weight_Divider;

      return isNaN(result)? '-':isFinite(result)? result:'-';
    }

}
