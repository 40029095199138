import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MacMacchine } from '../objects/macMacchine';
import { SessionService } from '../services/session.service';
import { ConfigUtil } from '../utils/configUtils';
import { AlrElencoAllarmi } from '../objects/alrAlarmsList';
import { ModElencoModelli } from '../objects/modElencoModelli';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { map } from 'rxjs/operators';
import { ModelService } from '../services/model.service';
import { AlarmService } from '../services/alarm.service';
import { AlarmHistoryService } from '../services/alarm-history.service';
import { combineLatest } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { AlarmNamePipe } from '../utils/pipes/alarm-name.pipe';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['./alarms.component.scss'],
})
export class AlarmsComponent implements OnInit, OnDestroy {

  machineSessioned: MacMacchine = new MacMacchine();
  displayedColumns: string[] = ['name', 'saL_SxDx', 'saL_DateTime'];
  dataSourceAlarms;
  alarmsList: AlrElencoAllarmi[] = [];
  modelsList: ModElencoModelli[] = [];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private sessionService: SessionService,
    private route: Router,
    private modelService:ModelService,
    private alarmService:AlarmService,
    private alarmHistoryservice:AlarmHistoryService,
    private datePipe: DatePipe
  ) {
      this.machineSessioned = JSON.parse(this.sessionService.getGenericSession(ConfigUtil.machineSessioned));
  }

  ngOnInit() {
    const model$ = this.modelService.listObservable;  
    const alarm$ = this.alarmService.listObservable;  
    const history$ = this.alarmHistoryservice.read(this.machineSessioned.maC_Numero_Serie_MS);
    
    combineLatest( model$, alarm$,history$).pipe(
      map(([model$, alarm$,history$]) => ({
        modelList: model$,
        alarmList:alarm$,
        historyList:history$
      })))
    .subscribe(pair => {
      this.alarmsList = pair.alarmList;
      this.modelsList = pair.modelList;
      let tmpSalStoricoallarmi = pair.historyList;


      this.dataSourceAlarms = new MatTableDataSource(tmpSalStoricoallarmi);
      this.setCustomDataAccessor();
      this.dataSourceAlarms.paginator = this.paginator;
      this.dataSourceAlarms.sort = this.sort;
    });
  }

  setCustomDataAccessor() {
    const alarmPipe = new AlarmNamePipe(this.sessionService);
  
    this.dataSourceAlarms.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return alarmPipe.transform(item,this.alarmsList,this.modelsList);
        default:
          return item[property];
      }
    };
  }

  applyFilter(filterValue: string) {
    this.dataSourceAlarms.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(): void {
    // destroy component on destroy

    this.dataSourceAlarms = [];
    console.log("close alarms")
    this.route.dispose();
  }
}
