import { Pipe, PipeTransform } from '@angular/core';
import { version } from 'process';

@Pipe({
  name: 'swVersion'
})
export class SwVersionPipe implements PipeTransform {

  transform(version: string, args?: any): any {
    if(version!=null){
      switch(version.length){
        case 9:
          return version.substring(0,1)+"."+version.substring(1,2)+"."+version.substring(2,3)+"-"+version.substring(3,5)+"."+version.substring(5,7)+"."+version.substring(7,9);
        case 8:
          return version.substring(0,1)+"."+version.substring(1,2)+"-"+version.substring(2,4)+"/"+version.substring(4,6)+"/"+version.substring(6,8);
        default:
          return version;

      }
    }
    else
      return version;
  }

}
