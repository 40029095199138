<div class="main-container">
  <div class="inside-main-container"
    *ngIf="machineSessioned.maC_ModelloDx === 0 || machineSessioned.maC_ModelloDx === null || machineSessioned.maC_ModelloDx === undefined || machineSessioned.maC_ModelloDx === machineSessioned.maC_ModelloSx"
    [ngSwitch]="machineSessioned.maC_ModelloSx">
    <app-realtime-soft *ngSwitchCase="1"></app-realtime-soft>
    <app-realtime-gx *ngSwitchCase="2"></app-realtime-gx>
    <app-realtime-soft-touch *ngSwitchCase="3"></app-realtime-soft-touch>
    <app-realtime-soft-touch *ngSwitchCase="4"></app-realtime-soft-touch>
    <app-realtime-twist *ngSwitchCase="5"></app-realtime-twist>
    <app-realtime-peb *ngSwitchCase="6"></app-realtime-peb>
    <app-realtime-mant *ngSwitchCase="7"></app-realtime-mant>
    <app-realtime-titan *ngSwitchCase="8"></app-realtime-titan>
    <app-realtime-peb *ngSwitchCase="10"></app-realtime-peb>
    <app-realtime-chef *ngSwitchCase="11"></app-realtime-chef>
    <app-realtime-titan *ngSwitchCase="12"></app-realtime-titan><!-- è una titan anche il modello 12 -->
    <mat-card *ngSwitchDefault>
      {{ 'work_in_progress' | translate }}
    </mat-card>
  </div>

  <div class="inside-main-container"
    *ngIf="machineSessioned.maC_ModelloDx !== 0 && machineSessioned.maC_ModelloDx !== null && machineSessioned.maC_ModelloDx !== undefined && machineSessioned.maC_ModelloDx !== machineSessioned.maC_ModelloSx">
    <app-realtime-twinchef></app-realtime-twinchef> <!-- twinchef modello 11 e 12 -->
  </div>
</div>
