import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FirFirmware } from '../objects/firFirmware';
import { AppConfigService } from './app-config.service';
import { GenericCrudService } from './generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class FirmwareService extends GenericCrudService<FirFirmware,number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
   super(http ,appConfigService,"firmware");
   this.load();
 }

 download(Fir_id: number): Observable<Blob> {
  return this.http.get(`${this.endpoint}/${this.apiName}/FIR_Id=${Fir_id}/download`, {
    responseType: 'blob'
  })

}



createFile(firmware: FirFirmware): Observable<any> {
  var fd = new FormData();
  fd.append('fiR_File', firmware.fiR_File);
  fd.append('fiR_Name', firmware.fiR_Name);
  fd.append('fiR_Description', firmware.fiR_Description);
  fd.append('fiR_MOD_Id', JSON.stringify(firmware.fiR_MOD_Id));
  fd.append('fiR_ReleaseDate', firmware.fiR_ReleaseDate);
  fd.append('fiR_Version', firmware.fiR_Version);
  return this.http.post<any>(`${this.endpoint}/${this.apiName}`, fd);
}
}
