import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-about-modal',
  templateUrl: './about-modal.component.html',
  styleUrls: ['./about-modal.component.scss'],
})
export class AboutModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AboutModalComponent>
    ) { }

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close();
  }

}
