import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';
import { MachineConnectedComponent } from '../../machine-connected/machine-connected';
import { AwsIotProviderService } from '../../services/aws-iot-provider.service';
import { LoaderService } from '../../services/loader.service';
import { ModelService } from '../../services/model.service';
import { SessionService } from '../../services/session.service';
import { SnackbarService } from '../../services/snackbar.service';
import { FrigomatTopicsPub, FrigomatTopicsSub } from '../../utils/enums/frigomatTopics';

@Component({
  selector: 'app-realtime-soft-touch',
  templateUrl: './realtime-soft-touch.component.html',
  styleUrls: ['./realtime-soft-touch.component.scss']
})
export class RealtimeSoftTouchComponent extends MachineConnectedComponent {

  constructor(
    protected translateService: TranslateService,
    protected awsIotProviderService: AwsIotProviderService,
    protected sessionService:SessionService,
    protected snackBarService:SnackbarService,
    protected loaderService:LoaderService,
    protected modelService: ModelService
  ) {
    super(awsIotProviderService, sessionService,modelService);
  }

  machineSessionedStringSerial = '';
  modelSessioned = '';
  public showLoading: boolean = true;
  realtimeList: [number,any,any,number][]=[];

  protected connect() {
    this.realtimeList=[];

    if(this.machineSession.maC_ModelloSx!= null && this.machineSession.maC_ModelloSx!= 0)
      this.realtimeList.push([1,null,null,this.machineSession.maC_ModelloSx]);
 
    if(this.machineSession.maC_ModelloDx!= null && this.machineSession.maC_ModelloDx!= 0)
      this.realtimeList.push([2,null,null,this.machineSession.maC_ModelloDx]);
 
    if(this.machineSession.maC_ModelloCen!= null && this.machineSession.maC_ModelloCen!= 0)
      this.realtimeList.push([3,null,null,this.machineSession.maC_ModelloCen]);
    
    this.subscribe(FrigomatTopicsSub.currenStatusSection,this.statusArrived);
    this.subscribe(FrigomatTopicsSub.realtimeSection,this.realTimeArrived);
    this.publish(FrigomatTopicsPub.recipeList);
    const numbers = timer(200);
    numbers.subscribe(x =>{
      this.getStatus();
    });
  }

  getStatus() {
    this.publish(FrigomatTopicsPub.currenStatus);
    this.publish(FrigomatTopicsPub.realtime);
  }

  realTimeArrived(data: any,app:any){
    const topic = data.value[Object.getOwnPropertySymbols(data.value)[0]];
    let sector = Number(topic.split('/')[3]);
    let realTime = app.realtimeList.find(x=>x[0]==sector);
    realTime[1] = data.value;
  }

  statusArrived(data: any, app:any){
    const topic = data.value[Object.getOwnPropertySymbols(data.value)[0]];
    let sector = Number(topic.split('/')[3]);
    let realTime = app.realtimeList.find(x=>x[0]==sector);
    if(realTime!=null || realTime.utcDateTime<data.value.utcDateTime)
      realTime[2] = data.value;
  }

  stop(section:number)
  {
    this.publish(FrigomatTopicsPub.stopCmdSection.replace('[section]',section.toString()),1);
  }

  startConservation(section:number)
  {
    this.publish(FrigomatTopicsPub.conservationCmdSection.replace('[section]',section.toString()),1);
  }
}