import { Subject } from 'rxjs';
import { Component, OnInit } from "@angular/core";
import { Param } from "../objects/param";
import { SessionService } from "../services/session.service";
import { MacMacchine } from "../objects/macMacchine";
import { ParameterService } from "../services/parameter.service";

@Component({
  selector: "app-parameter-linear",
  templateUrl: "./parameter-linear.component.html",
  styleUrls: ["./parameter-linear.component.scss"],
})
export class ParameterLinearComponent implements OnInit {
  parametersLinear: Param[] = [];
  listSectors = [];
  machineSessioned: MacMacchine;

  constructor(private sessionService: SessionService,
    private parameterService:ParameterService ) {}

  ngOnInit(): void {
    this.sessionService.currentMachineSubject.subscribe((res) => {
      this.listSectors = [];
      this.machineSessioned = res;
      if (this.machineSessioned.maC_ModelloSx === 2) {
        if (this.machineSessioned.maC_VersSwQSx !== null) {
          this.listSectors.push(1);
          this.listSectors.push(2);
        }
        if (this.machineSessioned.maC_VersSwVSx !== null) {
          this.listSectors.push(3);
          this.listSectors.push(4);
        }
        if (this.machineSessioned.maC_VersSwQDx !== null) {
          this.listSectors.push(5);
          this.listSectors.push(6);
        }
        if (this.machineSessioned.maC_VersSwVDx !== null) {
          this.listSectors.push(7);
          this.listSectors.push(8);
        }
      }
      if (this.machineSessioned.maC_ModelloSx !== 2) {
        if (this.machineSessioned.maC_ModelloSx > 0) {
          this.listSectors.push(1);
        }
        if (this.machineSessioned.maC_ModelloDx > 0) {
          this.listSectors.push(2);
        }
        if (this.machineSessioned.maC_ModelloCen > 0) {
          this.listSectors.push(3);
        }
      }

//.parameterService.listObservable().subscribe();
    });
  }

  NewValue(param: Param) {
    console.log(param);
  }


}
