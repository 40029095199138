<mat-progress-bar *ngIf="loading && machineConnected" mode="indeterminate"></mat-progress-bar>
<div class="offlineCard" *ngIf="showAlert">
  <mat-card class="mat-elevation-z8">
    <h2>{{ 'machine_offline' | translate }}</h2>
    <mat-icon>perm_scan_wifi</mat-icon>
  </mat-card>
</div>

<div class="main-container" *ngIf="!showAlert">
  <div class="inside-main-container">
    <div class="top-right">
      <label *ngIf="showDate"><small>{{ 'last_update' | translate}}: {{last_update | date:'dd/MM/yyyy HH:mm'}}</small></label>
      <mat-label>
        <mat-icon [ngStyle]="{'color': machineConnected?'green':'red'}">wifi</mat-icon>
      </mat-label>
    </div>
    <mat-card [class]="!showDate?'mat-elevation-z8':'not'">
      <div *ngFor="let parameter of parametersList">
        <div ngSwitch="parameter.parameterUM">
          <app-parameter-ctrl *ngSwitchCase="'time'"   (newValue)="NewValue($event)" [param]="parameter" [isEditable]="!loading && machineConnected"></app-parameter-ctrl>
   
          <app-parameter-ctrl *ngSwitchDefault  (newValue)="NewValue($event)" [param]="parameter" [isEditable]="!loading && machineConnected"></app-parameter-ctrl>
   
        </div>
      </div>
    </mat-card>
    <button *ngIf="machineConnected" mat-raised-button color="primary" [disabled]="loading" (click)="save()">{{ 'save' | translate }}</button>
  </div>
</div>