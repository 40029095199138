import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler,HttpRequest, HttpErrorResponse, HttpResponse} from "@angular/common/http";
import { throwError, Observable, BehaviorSubject, of, from } from "rxjs";
import { catchError, filter, take, switchMap, finalize, tap } from "rxjs/operators";
import { AppConfigService } from './app-config.service'
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { UserLogged } from '../objects/user-logged';
import { ConfigUtil } from '../utils/configUtils';
import { SessionService } from "./session.service";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  private AUTH_HEADER = "Authorization";
  private token = "secrettoken";
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private appConfigService:AppConfigService, 
    private router: Router, 
    private authenticationService:AuthenticationService,
    private sessionService:SessionService)
  {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /*if (!req.headers.has('Content-Type')) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
    }*/
   

    req = this.addAuthenticationToken(req);

    return next.handle(req).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
            if(evt.headers && evt.ok )
            {
              let token = evt.headers.get('X-Token');
              if(token!=null){
                let us = new UserLogged(token);
                this.sessionService.setLoginSession(us);
              } 
            }
          }
        }),
                
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          this.authenticationService.logout();
          this.router.navigate(['/login']);
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    if(this.sessionService.currentUserValue!=null){
      this.token = this.sessionService.currentUserValue.token;
    }
    else{
      this.token=null;
    }

    if (!this.token) {
      return request;
    }
    // If you are calling an outside domain then do not add the token.
    if (!request.url.match(this.appConfigService.apiBaseUrl)) {
      return request;
    }
    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, "Bearer " + this.token)
    });
  }
}