
<div class="main-container">
  <div class="inside-main-container">
    <div class="offlineCard" *ngIf="showAlert">
      <mat-card>
        <h2>{{ 'machine_offline' | translate }}</h2>
        <mat-icon>perm_scan_wifi</mat-icon>
      </mat-card>
    </div>
    <mat-card *ngIf="showLoading">
      <h3>{{ 'waiting' | translate }}...</h3>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card>
    <mat-card *ngIf="!showLoading && !showAlert">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3>{{ 'add_recipe' | translate }}</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="example-container">
            <div  [ngSwitch]="model.moD_Codice">
              <span *ngSwitchCase="3">
                  <app-recipe-soft-form  #child  [entityList]="recipeList"></app-recipe-soft-form>  
                  <button mat-raised-button color="primary" (click)="addRecipe()" [disabled]="!child.form.valid" >{{ 'add_recipe' | translate }}</button>
              </span>
              <span *ngSwitchCase="4">
                <app-recipe-soft-ht-form  #child  [entityList]="recipeList"></app-recipe-soft-ht-form>  
                <button mat-raised-button color="primary" (click)="addRecipe()" [disabled]="!child.form.valid" >{{ 'add_recipe' | translate }}</button>
              </span>
              <span *ngSwitchCase="5">
                <app-recipe-twist-form  #child  [entityList]="recipeList"></app-recipe-twist-form>  
                <button mat-raised-button color="primary" (click)="addRecipe()" [disabled]="!child.form.valid" >{{ 'add_recipe' | translate }}</button>
              </span>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card>
    <br>
    <mat-card *ngIf="!showLoading && !showAlert">
      <h3>{{ 'recipe_list' | translate }}</h3>
      <mat-card-content>
        <mat-form-field style="width: 100%;">
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'filter_label' | translate }}">
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
          <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'name' | translate }}</th>
              <td mat-cell *matCellDef="let row"> <b>{{row.name || uppercase }}</b> </td>
          </ng-container>

          <ng-container matColumnDef="duration" >
              <th mat-header-cell *matHeaderCellDef>{{ 'duration_s' | translate }}</th>
              <td mat-cell *matCellDef="let row"  > {{row.duration}} </td>
          </ng-container>

          <ng-container matColumnDef="temperature" >
            <th mat-header-cell *matHeaderCellDef>{{ 'temperature_c' | translate }}</th>
            <td mat-cell *matCellDef="let row"  > {{row.temperature}}</td>
          </ng-container>

          <ng-container matColumnDef="type" >
            <th mat-header-cell *matHeaderCellDef >{{ 'type' | translate }}</th>
            <td mat-cell *matCellDef="let row" >  {{row.type | recipeTwist }} </td>
          </ng-container>

          <ng-container matColumnDef="consistency" >
            <th mat-header-cell *matHeaderCellDef >{{ 'consistency' | translate }}</th>
            <td mat-cell *matCellDef="let row" > {{row.consistency }} </td>
          </ng-container>

          <ng-container matColumnDef="conservationOff" >
            <th mat-header-cell *matHeaderCellDef >{{ 'conservation_off_m' | translate }}</th>
            <td mat-cell *matCellDef="let row" > {{row.conservationOff }} </td>
          </ng-container>

          <ng-container matColumnDef="conservationOn" >
            <th mat-header-cell *matHeaderCellDef >{{ 'conservation_on_s' | translate }}</th>
            <td mat-cell *matCellDef="let row" > {{row.conservationOn }} </td>
          </ng-container>

          <ng-container matColumnDef="productionOff" >
            <th mat-header-cell *matHeaderCellDef >{{ 'production_off_m' | translate }}</th>
            <td mat-cell *matCellDef="let row" > {{row.productionOff }} </td>
          </ng-container>

          <ng-container matColumnDef="productionOn" >
            <th mat-header-cell *matHeaderCellDef >{{ 'production_on_s' | translate }}</th>
            <td mat-cell *matCellDef="let row" > {{row.productionOn }} </td>
          </ng-container>

          <ng-container matColumnDef="tankTemperature" >
            <th mat-header-cell *matHeaderCellDef >{{ 'tank_temperature_c' | translate }}</th>
            <td mat-cell *matCellDef="let row" > {{row.tankTemperature }} </td>
          </ng-container>

          <ng-container matColumnDef="creative" >
            <th mat-header-cell *matHeaderCellDef >{{ 'creative' | translate }}</th>
            <td mat-cell *matCellDef="let row" > <span ><mat-slide-toggle [checked]="row.creative" disabled="true" ></mat-slide-toggle></span>  </td>
          </ng-container>
          
          <ng-container matColumnDef="turbo" >
            <th mat-header-cell *matHeaderCellDef >{{ 'turbo_s' | translate }}</th>
            <td mat-cell *matCellDef="let row" > {{row.turbo }}</td>
          </ng-container>
          
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }} </th>
            <td mat-cell *matCellDef="let row">
              <button mat-raised-button  (click)="showAddDialog(row)" color="primary">{{ 'edit' | translate }}</button>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>
      </mat-card-content> 
    </mat-card>
  </div>
</div>