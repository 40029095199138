import { Component } from '@angular/core';
import { MqttService } from 'ngx-mqtt';
import { CommandService } from 'src/app/services/command.service';
import { SessionService } from 'src/app/services/session.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { RealtimeLcdComponent } from '../realtime-lcd/realtime-lcd.component';

@Component({
  selector: 'app-realtime-gx',
  templateUrl: './realtime-gx.component.html',
  styleUrls: ['./realtime-gx.component.scss']
})
export class RealtimeGxComponent extends RealtimeLcdComponent {

  constructor(
    protected mqttService: MqttService,
    protected sessionService: SessionService,
    protected snackBarService: SnackbarService,
    protected commandService:CommandService
    ) {
      super(mqttService ,sessionService,snackBarService,commandService);
    }

    getSectors() {
        if (this.machineSessioned.maC_VersSwQSx !== null) {
          this.listSectors.push(1);
          this.listSectors.push(2);
        }
  
        if (this.machineSessioned.maC_VersSwVSx !== null) {
          this.listSectors.push(3);
          this.listSectors.push(4);
        }
  
        if (this.machineSessioned.maC_VersSwQDx !== null) {
          this.listSectors.push(5);
          this.listSectors.push(6);
        }
  
        if (this.machineSessioned.maC_VersSwVDx !== null) {
          this.listSectors.push(7);
          this.listSectors.push(8);
        }
    }
}