<div class="main-container">
  <div class="inside-main-container">
    <mat-card class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSourceHeats">

        <!-- Position Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef> {{ 'datetime' | translate}} {{ 'start' | translate}} | {{ 'end' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.scT_DateTimeInizio | date: "dd/MM/yyyy hh:mm" }} | {{ element.scT_DateTimeFine | date: "hh:mm" }}
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> {{ 'type' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{ getTypeString(element.scT_Tipo) }} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="drisc">
          <th mat-header-cell *matHeaderCellDef> {{ 'durata' | translate}} {{ 'risc' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.scT_DurataRisc}}m </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="dover">
          <th mat-header-cell *matHeaderCellDef> {{ 'durata' | translate}} {{ 'over' | translate}} 65 °C </th>
          <td mat-cell *matCellDef="let element"> {{element.scT_DurataOver}}m </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="draff">
          <th mat-header-cell *matHeaderCellDef> {{ 'durata' | translate}} {{ 'raff' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.scT_DurataRaff}}m </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="tmax">
          <th mat-header-cell *matHeaderCellDef> {{ 'tmax' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.scT_TempMax}} °C </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="tmin">
          <th mat-header-cell *matHeaderCellDef> {{ 'tmin' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.scT_TempMin}} °C </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="ricetta">
          <th mat-header-cell *matHeaderCellDef> {{ 'recipe' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{setFlavourString(element.scT_Ricetta)}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="evento">
          <th mat-header-cell *matHeaderCellDef> {{ 'evento' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{setAlarmString(element.scT_Evento)}}  </td> <!-- {{element.sctEvento}} -->
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="peso">
          <th mat-header-cell *matHeaderCellDef> {{ 'weight' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.scT_Peso / 100 }} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="esito">
          <th mat-header-cell *matHeaderCellDef> {{ 'esito' | translate}} </th>
          <td mat-cell *matCellDef="let element">
            <label *ngIf="element.scT_Esito === 1"><mat-icon style="font-size: 1.7em; color: green;" >check_circle</mat-icon></label>
            <label *ngIf="element.scT_Esito !== 1"><mat-icon style="font-size: 1.7em; color: orange;" >error</mat-icon></label>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 50, 500, 1000]" showFirstLastButtons></mat-paginator>
    </mat-card>
  </div>
</div>
