import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kgCounter'
})
export class KgCounterPipe implements PipeTransform {

  transform(value: string, grams:number): any {
    if(grams==null || value==null){
      return "(- kg)"
    }
    else{
      return "("+(Number(value)*grams/1000).toFixed(1)+" kg)"
    }
  }

}
