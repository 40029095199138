import { NotNotificationSettings } from './../objects/notNotificationSettings';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { GenericCrudService } from './generic-crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationSettingsService extends GenericCrudService<NotNotificationSettings,number> {

  constructor( protected http: HttpClient, protected appConfigService:AppConfigService)
  {
      super(http ,appConfigService,"notificationSettings");
  }
  readByUser(userId:number): Observable<NotNotificationSettings[]> {
    return this.http.get<NotNotificationSettings[]>(`${this.endpoint}/${this.apiName}/${userId}`);
  }

  createMulti(objsToCreate: NotNotificationSettings[]): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/${this.apiName}`, objsToCreate);
  }
}
