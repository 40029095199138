import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';
import { MachineConnectedComponent } from 'src/app/machine-connected/machine-connected';
import { AwsIotProviderService } from 'src/app/services/aws-iot-provider.service';
import { ModelService } from 'src/app/services/model.service';
import { SessionService } from 'src/app/services/session.service';
import { FrigomatTopicsPub, FrigomatTopicsSub } from 'src/app/utils/enums/frigomatTopics';

@Component({
  selector: 'app-counters-soft-touch',
  templateUrl: './counters-soft-touch.component.html',
  styleUrls: ['./counters-soft-touch.component.scss']
})
export class CountersSoftTouchComponent extends MachineConnectedComponent{
  
  showLR:boolean;
  showCenter:boolean;
  counter:any=null;
  public showLoading: boolean = true;
  showAlert:boolean = false;

  constructor(
    protected awsIotProviderService: AwsIotProviderService,
    protected sessionService:SessionService,
    protected modelService: ModelService
  ) {
    super(awsIotProviderService, sessionService,modelService);
  }

  protected connect() {
    this.machineSession.maC_Grammi;
    console.log(this.machineSession.maC_Grammi);
    this.showLR =  this.machineSession.maC_ModelloDx!= null && this.machineSession.maC_ModelloDx!= 0;
    this.showCenter =  this.machineSession.maC_ModelloCen!= null && this.machineSession.maC_ModelloCen!= 0;
    
    this.subscribe(FrigomatTopicsSub.counters,this.countersArrived);
    this.publish(FrigomatTopicsPub.counters);

    const numbers = timer(10000);
    numbers.subscribe(x =>{
      if(this.showLoading)
      {
        this.showAlert =true;
        this.showLoading = false;
      }
    });
  }


  countersArrived(data: any, app:any){
    app.showLoading=false;
    app.counter = data.value;
  }

}
