import { Subject, timer } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { Param } from "../objects/param";
import { SessionService } from "../services/session.service";
import { MacMacchine } from "../objects/macMacchine";
import { ParameterService } from "../services/parameter.service";
import { AwsIotProviderService } from "../services/aws-iot-provider.service";
import { FrigomatTopicsPub, FrigomatTopicsSub } from "../utils/enums/frigomatTopics";
import { TranslateService } from "@ngx-translate/core";
import { SnackbarService } from "../services/snackbar.service";

@Component({
  selector: "app-parameter-non-linear",
  templateUrl: "../parameter-list/parameter-list.component.html",
  styleUrls: ["../parameter-list/parameter-list.component.scss"],
})
export class ParameterNonLinearComponent implements OnInit {
  parametersLinear: Param[] = [];
  listSectors = [];
  machineSession: MacMacchine;
  parametersList: Param[] = [];
  modifiedParametersList: Param[] = [];
  loading = false;
  last_update: Date;
  showDate: boolean = false;
  machineConnected=false;
  showAlert=false;

  constructor(
    private sessionService: SessionService,
    private awsService: AwsIotProviderService,
    private snackBarService: SnackbarService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    let time = timer(4000);

    let sub =time.subscribe(x => {
      this.showAlert =true;
    });

    this.loading = true;
    this.sessionService.currentMachineSubject.subscribe((res) => {
      this.machineSession = res;
      this.awsService.publish(FrigomatTopicsPub.parameters.replace("[SN]",this.machineSession.maC_Numero_Serie_MS.toString()));

      var topic = FrigomatTopicsSub.parameters.replace("[SN]",
        this.machineSession.maC_Numero_Serie_MS.toString()
      );
      this.awsService.subscribe(topic)
      .subscribe({
        next: (parametersList) => {
          sub.unsubscribe();
          this.machineConnected=true;
          console.log(parametersList);
          this.parametersList=[];
          this.modifiedParametersList =[];
          parametersList.value.forEach(element => {
            var type = element.type ? element.type.split(".") : [];
            var value = Array.isArray(element.value)?element.value:[element.value]
            if(this.machineSession.maC_ModelloSx!=4 || (element.id!=143 && element.id!=145)){
              this.parametersList.push({
                value: value,
                id: element.id,
                default: element.default,
                description: "P"+ (element.id%100) .toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) +" "+ element.description,
                parameterUM: type.length>0 ? type[0] : "",
                minValue: element.min,
                maxValue: element.max,
                ctrlType: element.list ? (element.list[0] === "Off" ? 1 : 2) : 0,
                step: element.step,
                multiplierValue: type.length>1 ? +Math.pow(10,+type[1]) : 1,
                labels: element.list,
              });
            }
          });
          this.loading = false;
        },
        error: (error) => {
          console.error(error);
          this.loading = false;
        },
        complete: () => console.log("Done"),
      });
    });
  }

  NewValue(param: Param) {
    let idx = this.modifiedParametersList.findIndex(x=>x.id==param.id);
    if(idx>-1){
      this.modifiedParametersList[idx].value= param.value;
    }else{
      this.modifiedParametersList.push(param);
    }
    console.log(param);
  }

  save(){
    this.loading = true;
    let newParameters=[];
    this.modifiedParametersList.forEach((element) => {
      var value: any;
      if(element.value.length > 1){
        value = element.value;
      } else {
        value = element.value[0];
      }
      newParameters.push({id:element.id, value:value});
    });

    this.awsService.publish(FrigomatTopicsPub.setParameters.replace("[SN]",this.machineSession.maC_Numero_Serie_MS.toString()), newParameters);
    this.snackBarService.showSnackBar(
      this.translateService.instant("operation_successfully")
    );
    this.modifiedParametersList =[];
    this.loading = false;

  }
}
