import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FlvFlavour } from 'src/app/objects/flvFlavour';
import { MacMacchine } from 'src/app/objects/macMacchine';
import { ScoStoricoComandi } from 'src/app/objects/scoStoricoComandi';
import { SessionService } from 'src/app/services/session.service';
import { ConfigUtil } from '../configUtils';

@Pipe({
  name: 'flavourName'
})
export class FlavourNamePipe implements PipeTransform {

  machineSessioned: MacMacchine = new MacMacchine();
  constructor(protected sessionService: SessionService,
    protected translateService:TranslateService
    ) {
    this.machineSessioned = JSON.parse(this.sessionService.getGenericSession(ConfigUtil.machineSessioned));
  }

  transform(value: ScoStoricoComandi, flavourList:FlvFlavour[],flavourListTwin:FlvFlavour[], modelNumList:number[]): any {
    let idToPass = this.machineSessioned.maC_ModelloSx;
      // solo la GX è diversa DA tutte le altre, la filtro nell'if

    if(this.machineSessioned.maC_ModelloSx !== 2){
      // tytte le altre macchine
      idToPass = 0;
      if(value.scO_SxDx === 1){ idToPass = this.machineSessioned.maC_ModelloSx; }
      if(value.scO_SxDx === 2){ idToPass = this.machineSessioned.maC_ModelloDx; }
      if(value.scO_SxDx === 3){ idToPass = this.machineSessioned.maC_ModelloCen; }
    }
    var sec = modelNumList.findIndex(x=>x==idToPass);

    if(value.scO_Codice == 0){
      return "-";
    }
    switch(sec){
      case 1:{
        let recipe = flavourListTwin.find(x=>x.index==value.scO_Ricetta);
        if(recipe!=null){
          return recipe.locked? this.translateService.instant(recipe.name):recipe.name
        }
        if(value.scO_Ricetta==null || value.scO_Ricetta==0 ){
          return "-";
        }
        return "#"+this.translateService.instant("flavour")+ " "+value.scO_Ricetta;
      }
      default:
      {
        let recipe = flavourList.find(x=>x.index==value.scO_Ricetta);
        if(recipe!=null){
          return recipe.locked? this.translateService.instant(recipe.name):recipe.name
        }
        if(value.scO_Ricetta==null || value.scO_Ricetta==0){
          return "-";
        }
        return "#"+this.translateService.instant("flavour")+ " "+value.scO_Ricetta;
      }
    }

  }

}
