
    <form [formGroup]="form" autocomplete="off">
      <ng-container formArrayName="recipes">
        <mat-card>
          <h3>{{ title}}</h3>
          <mat-card-content>
            <mat-progress-bar *ngIf="!dataSourceFlavours" mode="indeterminate"></mat-progress-bar>
            <mat-form-field style="width: 100%;">
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'filter_label' | translate }}...">
            </mat-form-field>
            <table mat-table [dataSource]="dataSourceFlavours" matSort>
              <ng-container matColumnDef="index" >
                <th mat-header-cell *matHeaderCellDef  mat-sort-header> {{ 'index' | translate}} </th>
                <td mat-cell *matCellDef="let element" [formGroup]="element"> {{ element.get('index').value}} </td>
              </ng-container>
              <ng-container matColumnDef="locked">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header><label>{{ 'custom' | translate}}</label></th>
                <td mat-cell *matCellDef="let element" [formGroup]="element">
                  <label><mat-icon matTooltip="{{(element.get('locked').value? 'default': 'custom' )| translate }}" aria-hidden="true">{{element.get('locked').value?'lock':'edit'}}</mat-icon></label>
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header> {{ 'name' | translate}} </th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                  <mat-form-field *ngIf="!element.get('locked').value"  >
                    <input matInput type="text" formControlName="name"  placeholder="{{singleLabel + ' ' +element.get('index').value}}">
                  </mat-form-field>
                  <span *ngIf="element.get('locked').value">{{(element.get('name').value)}}  </span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-raised-button color="light-blue" (click)="reload()">{{ 'reload' | translate }}</button>
            <button mat-raised-button color="primary" (click)="saveFlavourList()"> {{ 'save' | translate }}</button>
          </mat-card-actions>
        </mat-card>
      </ng-container>
    </form>

