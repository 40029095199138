import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModElencoModelli } from '../objects/modElencoModelli';
import { AppConfigService } from './app-config.service';
import { GenericCrudService } from './generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class ModelService  extends GenericCrudService<ModElencoModelli,number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
   super(http ,appConfigService,"Model");
   this.load();
 }


 get(id:number): Observable<ModElencoModelli> {
  console.log(this.apiName);
  return this.http.get<ModElencoModelli>(`${this.endpoint}/${this.apiName}?id=${id}`);
}
}
