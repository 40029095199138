import { OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'zen-observable';
import { AwsIotProviderService } from '../services/aws-iot-provider.service';
import { MacMacchine } from '../objects/macMacchine';
import { SessionService } from '../services/session.service';
import { ConfigUtil } from '../utils/configUtils';
import { AnyMxRecord } from 'dns';
import { ModelService } from '../services/model.service';
import { ModElencoModelli } from '../objects/modElencoModelli';

export abstract class MachineConnectedComponent implements OnInit,OnDestroy {
  
  machineSession: MacMacchine = new MacMacchine();
  loading: boolean = false;
  messageRecipeResponse: string = "";
  serialOven:string;
  protected subs:Subscription[] =[];
  protected model:ModElencoModelli;

  constructor(
    protected awsIotProviderService: AwsIotProviderService,
    protected sessionService:SessionService,
    protected modelService: ModelService
  ) { 
    this.machineSession = JSON.parse(this.sessionService.getGenericSession(ConfigUtil.machineSessioned));
  }

  ngOnInit(): void {
    this.modelService.listObservable.subscribe(list=>{
      this.model = list.find(x=>x.moD_Codice==this.machineSession.maC_ModelloSx);
      this.connect();
    });
  }

  ngOnDestroy(): void {
    let a =this;
    this.subs.forEach(x=>{
      a.awsIotProviderService.unsubscribe(x);
    })
  }

  protected abstract connect();

  protected publish(topic:string,message:any='')
  {
    this.awsIotProviderService.publish(topic.replace('[SN]',this.machineSession.maC_Numero_Serie_MS.toString()),message);
  }

  protected subscribe(topic:string, callback:(data:any,object:any) => void)
  {
    var app = this;
    topic = topic.replace('[SN]',this.machineSession.maC_Numero_Serie_MS.toString());
    this.subs.push(this.awsIotProviderService.subscribe(topic).subscribe({
      next: data => callback(data,app),
      error: error => {
        console.error(error);
      },
      complete: () => console.log('Done'),
    }));
  }
}
