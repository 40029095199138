import { FormControl, FormGroup } from "@angular/forms";

export class FlvFlavour {
    id:number;
    index:number;
    locked:boolean;
    visible:boolean;
    name:string;

    static asFormGroup(flv: FlvFlavour): FormGroup {
        const fg = new FormGroup({
            id: new FormControl(flv.id),
            index: new FormControl(flv.index),
            locked: new FormControl(flv.locked),
            visible: new FormControl(flv.visible),
            name:new FormControl(flv.name),
          });
        return fg;
      }
}