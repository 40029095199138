import { Injectable, ViewChild } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnalysisGuard implements CanActivate {

  // @ViewChild(AnalysisComponent) anaklysisChild: AnalysisComponent;

  constructor(
    private route: Router
  ){}

  canActivate(): boolean {
    console.log("canguard analysis")
    this.route.dispose();
    timeout(1000);
    this.route.dispose();
    return true;
  }
}
