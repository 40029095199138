<button *ngIf="show && !editing" mat-icon-button matTooltip="{{'edit' | translate }}" (click)="edit()">
    <mat-icon class="icona" matSuffix color="primary">edit</mat-icon>
</button>
<button *ngIf="show && editing" mat-icon-button matTooltip="{{'save' | translate }}" (click)="save()">
    <mat-icon class="icona" matSuffix color="success">check</mat-icon>
</button>
<button *ngIf="show && editing" mat-icon-button matTooltip="{{'default' | translate }}" (click)="cancel()">
    <mat-icon class="icona ultima-icona" matSuffix color="warn">close</mat-icon>
</button>

<!-- devo comunque inserire un'icona vuota per mantenere il layout -->
<button *ngIf="!show" mat-icon-button>
    <mat-icon class="icona" matSuffix></mat-icon>
</button>
