import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MacMacchine } from 'src/app/objects/macMacchine';
import { ModElencoModelli } from 'src/app/objects/modElencoModelli';
import { Recipe} from 'src/app/objects/recipe';
import { ModelService } from 'src/app/services/model.service';
import { SessionService } from 'src/app/services/session.service';
import { ConfigUtil } from 'src/app/utils/configUtils';

@Component({
  selector: 'app-edit-recipe',
  templateUrl: './edit-recipe.component.html',
  styleUrls: ['./edit-recipe.component.scss']
})
export class EditRecipeComponent {

  @ViewChild('child')

  private childComponent!: any;

  entity:Recipe;
  entityList:Recipe[];
  title:string;
  recipeVal:any;
  model:ModElencoModelli;
  machineSession: MacMacchine;

  constructor(
    public dialogRef: MatDialogRef<EditRecipeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected modelService: ModelService,
    protected sessionService:SessionService
  ) { 
    this.machineSession = JSON.parse(this.sessionService.getGenericSession(ConfigUtil.machineSessioned));
  
  }

  protected connect() { 
  }

  ngOnInit() {
    this.modelService.listObservable.subscribe(list=>{
      this.model = list.find(x=>x.moD_Codice==this.machineSession.maC_ModelloSx);
      this.connect();
    });
    this.entity = this.data.element;
    this.entityList = this.data.listElements;
  }

  
  update(isNew:boolean=false){

    if(this.childComponent.update(isNew))
    this.dialogRef.close({success:true,msg:'recipe_successfully_changed'});
  }

  delete()
  {
    if(this.childComponent.delete())
    this.dialogRef.close({success:true,msg:'recipe_successfully_deleted'});
  }

}
