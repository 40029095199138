<h4 mat-dialog-title>{{'edit_association' | translate}}</h4>
<div mat-dialog-content>
      <form [formGroup]="form" id="ngForm" (ngSubmit)="save()">
  
        <mat-form-field>
          <input matInput placeholder="{{ 'address' | translate}}" formControlName="aum_addressCtrl"> 
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="{{ 'model' | translate}}"  formControlName="aum_modelCtrl" required >
            <mat-option *ngFor="let model of machinesModelsList" [value]="model.emA_Id">{{model.emA_Descrizione}} </mat-option> 
          </mat-select>
        </mat-form-field>
        <!--<mat-form-field *ngIf="showCombinata">
          <mat-label>{{ 'combinata' | translate}}</mat-label>
          <mat-select  formControlName="aum_model2Ctrl">
            <mat-option *ngFor="let model of modelListType2" value="{{model.emA_Id}}">{{model.emA_Descrizione}} </mat-option>
          </mat-select>
        </mat-form-field>-->
        <small *ngIf="machinesModelsList.length < 1" style="color: orange; padding-bottom: 15px;">Il modello sarà impostabile quando la scheda avrà comunicato col portale.</small>
        <mat-form-field>
          <mat-select placeholder="{{ 'dealer' | translate}}" formControlName="aum_dealerCtrl">
            <mat-option *ngFor="let user of userList|userInRole:[userRole.Admin,userRole.Dealer]" [value]="user.utE_Id">{{user.utE_Nome}} </mat-option> <!-- Dealer -->
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="{{ 'customer' | translate}}" formControlName="aum_customerCtrl" required>
            <mat-option *ngFor="let user of userList|userInRole:[userRole.Customer]" [value]="user.utE_Id">{{user.utE_Nome}} </mat-option> <!-- Customer -->
          </mat-select>
          <mat-error *ngIf= "form.controls.aum_customerCtrl.errors?.required">
            {{ 'specify_customer' | translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-select placeholder="{{ 'technicians' | translate}}" formControlName="aum_techsCtrl" multiple>
            <mat-option  *ngFor="let user of userList|userInRole:[userRole.Technician]"  [value]="user.utE_Id">{{user.utE_Nome}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input matInput maxlength="3" placeholder="{{ 'grammi' | translate}}" formControlName="aum_gramsCtrl" required>
        </mat-form-field>
        <label id="example-radio-group-label">{{ "payment_state" | translate }}</label>
        <mat-radio-group formControlName="aum_paymentCtrl" required>
          <mat-radio-button [value]="0" ><mat-icon style="font-size: 2em; color: lightgray;">circle</mat-icon></mat-radio-button><br/>
          <mat-radio-button [value]="2"><mat-icon  style="font-size: 2em; color: yellow;">circle</mat-icon></mat-radio-button><br/>
          <mat-radio-button [value]="1"><mat-icon style="font-size: 2em; color: green;">circle</mat-icon></mat-radio-button><br/>
          <mat-radio-button [value]="3"><mat-icon  style="font-size: 2em; color: red;">circle</mat-icon></mat-radio-button>
        </mat-radio-group>
      </form>
</div>
<div mat-dialog-actions>
  <button style="width: 100%" [disabled]="!form.valid" form="ngForm" type="submit" mat-raised-button color="primary" type="submit">{{ 'confirm' | translate }}</button>
  <button style="width: 100%" color="primary" mat-button mat-dialog-close>{{ 'close' | translate }}</button>
</div>  