<div class="main-container">
  <div class="inside-main-container">
    <mat-card class="mat-elevation-z8">
      <mat-progress-bar *ngIf="!dataSourceMachine" mode="indeterminate"></mat-progress-bar>

      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'filter_label' | translate }}...">
      </mat-form-field>
      <table mat-table [dataSource]="dataSourceMachine" matSort >
       <ng-container matColumnDef="maC_Matricola" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'serial' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.maC_Matricola}} </td>
        </ng-container>
        <ng-container matColumnDef="maC_Numero_Serie_MS">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'serial' | translate}} MS </th>
          <td mat-cell *matCellDef="let element"> {{element.maC_Numero_Serie_MS}} </td>
        </ng-container>
        <ng-container matColumnDef="maC_ModelString">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>  {{ 'model' | translate}}  </th>
          <td mat-cell *matCellDef="let element"> {{element.maC_ModelString}}
          </td>
        </ng-container>
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef>  {{ 'address' | translate}}  </th>
          <td mat-cell *matCellDef="let element"> {{element.maC_Indirizzo}} </td>
        </ng-container>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>  {{ 'select' | translate}}  </th>
          <td mat-cell *matCellDef="let element">
            <button mat-raised-button color="primary" (click)="selectMachine(element)">
              {{ 'select' | translate}} {{ 'machine' | translate}}
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[15, 25, 50, 100, 1000]"
        showFirstLastButtons></mat-paginator>
    </mat-card>
  </div>
</div>
