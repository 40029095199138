import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UteUser } from '../objects/uteUser';
import { AppConfigService } from './app-config.service';
import { GenericCrudService } from './generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends GenericCrudService<UteUser,number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
   super(http ,appConfigService,"user");
   this.load();
 }

 informativaUpdate(objToUpdate: UteUser): Observable<any> {
  return this.http.put<any>(`${this.endpoint}/${this.apiName}/informativa`, objToUpdate);
}

}
