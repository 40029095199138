import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'parametersDescr'
})
export class ParametersDescrPipe implements PipeTransform {

  constructor(protected translateService: TranslateService,
    ) {
  }

  transform(description: string,sector:number,sectorLen): any {
    var descr =this.translateService.instant(description);;
    if(sectorLen>1){
        if(sector==0){
          descr+= " "+this.translateService.instant("left");
        }
        if(sector==1){
          if(sectorLen==2)
          descr += " "+this.translateService.instant("right");
          else
          descr += " "+this.translateService.instant("center");
        }
        if(sector==2){
          descr += " "+this.translateService.instant("right");
        }
      }
    return descr;
  }

}
