<mat-toolbar *ngIf="showLeftMenuAndTopBar" class="topbar-content">
  <div>
    <button type="button" mat-button (click)="drawer.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <button type="button" mat-button (click)="goToFrigomatWebSite()" *ngIf="showLogo">
      <img *ngIf="showLogo" src="../../assets/img/logo.png">
    </button>
  </div>
  <div class="topbarMachinePrint">
    <mat-card *ngIf="topbarMachineSerial">
      <mat-label>{{ 'machine' | translate }} {{ 'selected' | translate }}</mat-label>
      <mat-label>{{topbarMachineSerial}}</mat-label>
    </mat-card>
  </div>
  <div>
    <mat-menu #appMenu="matMenu">
      <br /><label style="margin: 18px;" *ngIf="userSessioned"><b>{{userSessioned?.utE_Nome}}</b><br /><br /></label>
      <button mat-menu-item [matMenuTriggerFor]="download"><mat-icon>download</mat-icon> {{'download' | translate
        }}</button>
      <button mat-menu-item [matMenuTriggerFor]="lingue"><mat-icon>language</mat-icon> {{'lingua' | translate
        }}</button>
      <button mat-menu-item (click)="openAboutDialog()"><mat-icon>info</mat-icon> {{'about' | translate}}</button>
      <button mat-menu-item (click)="openDialog()"><mat-icon>logout</mat-icon> {{'logout' | translate}}</button>
    </mat-menu>
    <mat-menu #lingue="matMenu">
      <button mat-menu-item *ngFor="let language of languages"
        (click)="setLanguage(language.lng)"><mat-icon>translate</mat-icon> {{language.lngDesc}}</button>
    </mat-menu>
    <mat-menu #download="matMenu">
      <a mat-menu-item href="../../assets/download/automation_report.zip"><mat-icon>folder_zip</mat-icon> AUTOMATION
        REPORT</a>
    </mat-menu>
    <button mat-menu-item (click)="menuModeOpen == 'over' && drawer.close()" [matMenuTriggerFor]="appMenu">
      <mat-icon>settings</mat-icon>
    </button>
  </div>
</mat-toolbar>
<mat-drawer-container autosize [ngStyle]="!showLeftMenuAndTopBar &&  {'height' : '100%'} ">
  <mat-drawer opened="{{menuOpen}}" #drawer  mode="{{menuModeOpen}}">
    <mat-list role="list">
      <mat-list-item class="hoverClass" *ngFor="let menu of menuVoices"
        (click)="menuModeOpen == 'over' && drawer.toggle()" role="listitem" [routerLink]="menu.url"
        routerLinkActive="router-link-active"> <!-- [routerLink]="menu.url" -->
        <div><img [src]="menu.icon"> </div>
        <div style="width: 225px;">{{ menu.label | translate}}</div>
      </mat-list-item>
      <mat-list-item class="hoverClass" *ngIf="showAnalisys" role="listitem" routerLink="/analysis"
        routerLinkActive="router-link-active"> <!-- [routerLink]="menu.url" -->
        <div><img src="../assets/img/menuAnalisi.png"> </div>
        <div style="width: 225px;">{{ 'analisys' | translate}}</div>
      </mat-list-item>
      <mat-list-item class="hoverClass" *ngIf="showFlavours" role="listitem" routerLink="/flavours"
        routerLinkActive="router-link-active">
        <div><img src="../assets/img/menuRicette.png"> </div>
        <div style="width: 225px;">{{ recipeListLabel}}</div>
      </mat-list-item>
      <mat-list-item class="hoverClass" *ngIf="showRecipe" role="listitem" routerLink="/recipes"
        routerLinkActive="router-link-active">
        <div><img src="../assets/img/menuRicette.png"> </div>
        <div style="width: 225px;">{{ recipeListLabel}}</div>
      </mat-list-item>
      <mat-list-item class="hoverClass" *ngIf="machineSessioned" (click)="menuModeOpen == 'over' && drawer.toggle()"
        role="listitem" routerLink="/realtime" routerLinkActive="router-link-active">
        <div><img src="../assets/img/menuRealTime.png"> </div>
        <div style="width: 225px;">{{ "realtime" | translate}}</div>
      </mat-list-item>
      <mat-list-item class="hoverClass" *ngIf="machineSessioned && (userSessioned?.utE_Livello === userRole.Admin  || userSessioned?.utE_Livello === userRole.Technician)"
        (click)="menuModeOpen == 'over' && drawer.toggle()" role="listitem" routerLink="/parameters"
        routerLinkActive="router-link-active">
        <div><img src="../assets/img/programming.png"> </div>
        <div style="width: 225px;">{{ "programming" | translate}}</div>
      </mat-list-item>
      <mat-list-item class="hoverClass" *ngIf="showCounterMenu" (click)="menuModeOpen == 'over' && drawer.toggle()"
        role="listitem" routerLink="/counters" routerLinkActive="router-link-active">
        <div><img src="../assets/img/menuContatori.png"> </div>
        <div style="width: 225px;">{{ "counters" | translate}}</div>
      </mat-list-item>

      <mat-list-item class="hoverClass" *ngIf="userSessioned?.utE_Livello === userRole.Admin"
        (click)="menuModeOpen == 'over' && drawer.toggle()" role="listitem" routerLink="/users"
        routerLinkActive="router-link-active">
        <div><img src="../assets/img/menuUtenti.png"> </div>
        <div style="width: 225px;">{{ "users" | translate}}</div>
      </mat-list-item>
      <mat-list-item class="hoverClass" *ngIf="userSessioned?.utE_Livello === userRole.Admin"
        (click)="menuModeOpen == 'over' && drawer.toggle()" role="listitem" routerLink="/associations"
        routerLinkActive="router-link-active">
        <div><img src="../assets/img/menuAssociazioni.png"> </div>
        <div style="width: 225px;">{{ "associations" | translate}}</div>
      </mat-list-item>
      <mat-list-item class="hoverClass" *ngIf="userSessioned?.utE_Livello === userRole.Admin"
        (click)="menuModeOpen == 'over' && drawer.toggle()" role="listitem" routerLink="/firmwares"
        routerLinkActive="router-link-active">
        <div><img src="../assets/img/menuInfoSistema.png"> </div>
        <div style="width: 225px;">{{ "updateSWFW" | translate}}</div>
      </mat-list-item>
      <mat-list-item class="hoverClass" *ngIf="showNotificationSettings"
        (click)="menuModeOpen == 'over' && drawer.toggle()" role="listitem" routerLink="/notification-settings"
        routerLinkActive="router-link-active">
        <div><img src="../assets/img/menuNotifications.png"> </div>
        <div style="width: 225px;">{{ "notification_settings" | translate}}</div>
      </mat-list-item>
    </mat-list>
    <div class="side-script" *ngIf="showLeftMenuAndTopBar">
      <a routerLink="/privacy" target="_blanck">{{'privacy' |
      translate}}</a>
      <mat-label class="version">{{'version' | translate}}: {{version}} </mat-label>
    </div>
  </mat-drawer>
  <!-- router outlet  -->
  <router-outlet id="main-content" class="ion-router-class"></router-outlet>

</mat-drawer-container>
