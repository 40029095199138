import { Component, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AwsIotProviderService } from '../services/aws-iot-provider.service';
import { Recipe } from '../objects/recipe';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { EditRecipeComponent } from '../modals/edit-recipe/edit-recipe.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { timer } from 'rxjs';
import { MachineConnectedComponent } from '../machine-connected/machine-connected';
import { SessionService } from '../services/session.service';
import { FrigomatTopicsPub, FrigomatTopicsSub } from '../utils/enums/frigomatTopics';
import { MatPaginator } from '@angular/material/paginator';
import { ModelService } from '../services/model.service';
import { RecipeTwistFormComponent } from '../forms/recipe-twist-form/recipe-twist-form.component';
import { SnackbarService } from '../services/snackbar.service';

@Component({
  selector: 'app-recipe-book',
  templateUrl: './recipe-book.component.html',
  styleUrls: ['./recipe-book.component.scss']
})
export class RecipeBookComponent extends MachineConnectedComponent {

  messageRecipeResponse: string = "";
  recipeList: Recipe[];
  dataSource: MatTableDataSource<Recipe>;
  displayedColumnsTwist: string[] = ['name', 'type', 'consistency','duration', 'turbo','actions'];
  displayedColumnsSoft: string[] = ['name','consistency','tankTemperature', 'conservationOff', 'conservationOn','productionOff', 'productionOn','creative','actions'];
  displayedColumns:string[]=['name', 'consistency'];
  showLoading: boolean = true;
  showAlert:boolean = false;
  interval: any;

  @ViewChild('child')

  private childComponent!: RecipeTwistFormComponent;

  
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    protected translateService: TranslateService,
    protected awsIotProviderService: AwsIotProviderService,
    public dialog: MatDialog,
    protected snackBarLocked: MatSnackBar,
    protected sessionService: SessionService,
    protected modelService: ModelService,
    protected snackBarService: SnackbarService
  ) {
    super(awsIotProviderService, sessionService,modelService); 
  }

  protected connect() {

    if(this.model.moD_Codice==3 || this.model.moD_Codice==4){
      this.displayedColumns = this.displayedColumnsSoft;
    }
    if(this.model.moD_Codice==5){
      this.displayedColumns = this.displayedColumnsTwist;
    }

    const numbers = timer(10000);
    numbers.subscribe(x =>{
      if(this.showLoading)
      {
        this.showAlert =true;
        this.showLoading = false;
      }
    });
    this.showLoading=true;
    this.subscribe(FrigomatTopicsSub.recipeList,this.populateRecipeList)
    this.publish(FrigomatTopicsPub.recipeList);
  }

  showAddDialog(recipe: Recipe = null) {
    /*if (recipe != null && recipe.locked) {
      this.openSnackBarLocked();
    }
    else {*/
      const dialogRef = this.dialog.open(EditRecipeComponent, {
        width: '800px',
        data: {
          listElements: this.recipeList,
          element: recipe
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.success){
          this.snackBarService.showSuccessBar(this.translateService.instant(result.msg))
          const rep = timer(200);
          rep.subscribe(x=>{this.publish(FrigomatTopicsPub.recipeList)});
        }
      });
    //}
  }

  populateRecipeList(data: any,app:any) {
    app.showLoading=false;
    const topic = data.value[Object.getOwnPropertySymbols(data.value)[0]];
    if (data.value != '' ) {
      if(data.value.recipes!=undefined){
        app.recipeList = data.value.recipes;
      }
      else{
        app.recipeList=[];
      }
      app.dataSource = new MatTableDataSource(app.recipeList);
      app.dataSource.sort = app.sort;
      app.dataSource.paginator = app.paginator;
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      //this.dataSource.paginator.firstPage();
    }
  }

  openSnackBarLocked() {
    this.snackBarLocked.open(this.translateService.instant("recipe_locked"), this.translateService.instant("close"), { duration: 2500 });
  }

  addRecipe()
  {
    if(this.childComponent.update(true)){
      this.snackBarService.showSuccessBar(this.translateService.instant('recipe_successfully_added'));
      const rep = timer(200);
          rep.subscribe(x=>{this.publish(FrigomatTopicsPub.recipeList)});
    }
  }

}


