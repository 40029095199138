<mat-card class="mat-elevation-z8 realtime-container">
  <div class="card-content">
    <div class="mat-card-container">
      <mat-label>
        {{'status' | translate}}: <mat-icon
          [ngStyle]="{'color': realtime[2] || realtime[1]?'green':'red'}">wifi</mat-icon>
      </mat-label>
      <div class="states">
       {{'status' | translate}}
        <span>
          <mat-icon [ngClass]="{'rotate-8-s': realtime[2]?.beater.active}">{{realtime[2] |
            softTouchStatusIcon}}</mat-icon>
        </span>
      </div>
      <div class="states">
        <div><mat-icon>notifications</mat-icon> {{'alarms_on_now' | translate}}</div>
        <span *ngIf="realtime[2]">{{ (realtime[2]?.alarmsActive? 'yes':'-') | translate | uppercase }}</span>
      </div>
      <div class="states">
        <div>{{'consistency' | translate}}</div>
        {{realtime[1]?.consistency}}
      </div>
    </div>
    <div class="mat-card-container">
      {{'send_command' | translate }}
      <button mat-raised-button color="warn" (click)="stop(realtime[0])"> STOP</button>
    </div>
  </div>
</mat-card>
