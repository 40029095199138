import { Component, OnInit, OnDestroy} from '@angular/core';
import { MacMacchine } from '../objects/macMacchine';
import { SessionService } from '../services/session.service';
import { Router } from '@angular/router';
import { UserLogged } from '../objects/user-logged';
import { ConfigUtil } from '../utils/configUtils';
import { RegistrationService } from '../services/registration.service';
import { timer } from 'rxjs';
import { MachineService } from '../services/machine.service';

@Component({
  selector: 'app-singleunit',
  templateUrl: './singleunit.component.html',
  styleUrls: ['./singleunit.component.scss'],
})
export class SingleunitComponent implements OnInit, OnDestroy {

 
  userLogged: UserLogged;

  ngOnDestroy() {
    console.log('destroy singleunit');
    this.route.dispose();
  }

  machineSessioned: MacMacchine = new MacMacchine();
  dateLastCom:string;

  constructor(
    private registrationService: RegistrationService,
    private sessionService: SessionService,
    private route: Router,
    private machineService:MachineService
  ) {
    this.machineSessioned = JSON.parse(this.sessionService.getGenericSession(ConfigUtil.machineSessioned));
  }

  ngOnInit() {
    this.dateLastCom = this.machineSessioned.maC_LastUpdate;
    this.userLogged = this.sessionService.currentUserValue;
    this.registrationService.getFirst( this.machineSessioned.maC_Numero_Serie_MS).subscribe(res => {
      if (res !== null) {
        this.machineSessioned.rgS_Registrazione = res;
      }
      console.log(this.machineSessioned);
    });

    const source = timer(0, 60000);
    source.subscribe(arg => {
      this.machineService.get(this.machineSessioned.maC_Numero_Serie_MS).subscribe(x=>{
        this.dateLastCom = x.maC_LastUpdate;
      })
    });
    
  }

  checkPrintVersion(version: string){
    if(version !== null && version !== undefined && !version.startsWith("00") && version !== '`' && version !== '-'){
      return true;
    } else {
      return false;
    }
  }

  checkICCIDVisible(iccidString: string) {
    console.log("ICCID: ", this.isANumber(iccidString))
    return this.isANumber(iccidString);
  }

  isANumber(str){
    return !/\D/.test(str);
  }
}
