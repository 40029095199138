import { Component, ViewChild} from '@angular/core';
import { SessionService } from '../../services/session.service';
import { MacMacchine } from '../../objects/macMacchine';
import { Router } from '@angular/router';
import { FlavourService } from '../../services/flavour.service';
import { FlavoursAnalysis } from '../../objects/flavoursAnalysis';
import { AnalysisService } from '../../services/analysis.service';
import { ModelService } from '../../services/model.service';
import { combineLatest,  } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ModElencoModelli } from '../../objects/modElencoModelli';
import { LoaderService } from '../../services/loader.service';
import { AnalysisComponent, PieChart } from '../analysis.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-analysis-lcd',
  templateUrl: './analysis-lcd.component.html',
  styleUrls: ['./analysis-lcd.component.scss'],
})
export class AnalysisLcdComponent extends AnalysisComponent {

  flavoursArray:any = [];
  model:ModElencoModelli[]=[];
  modelListCod:number[];
  loading:boolean;
  filter=true;

  displayedColumns: string[] = ['scO_Ricetta', 'scO_Peso','scO_SxDx', 'scO_DateTime'];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSourceProduction;

  constructor(
    protected sessionService: SessionService,
    protected route: Router,
    private translateService: TranslateService,
    private flavourService: FlavourService,
    private analysisService: AnalysisService,
    private modelService:ModelService,
    private loaderService:LoaderService
  ) {
    super(route,sessionService)
    }

  ngOnInit() {
    this.machineSessioned = this.sessionService.currentMachineValue;  
    this.modelListCod=[];
    this.loaderService.isLoading.subscribe(x=>{
      this.loading = x>0;
    });

    if(this.machineSessioned.maC_ModelloSx==2){
      this.filter=false;
    }

    if(this.machineSessioned.maC_ModelloSx!=null && this.machineSessioned.maC_ModelloSx!=0) 
    {
      this.modelListCod.push(this.machineSessioned.maC_ModelloSx,);
    }
    if(this.machineSessioned.maC_ModelloCen!=null && this.machineSessioned.maC_ModelloCen!=this.machineSessioned.maC_ModelloSx && this.machineSessioned.maC_ModelloCen!=0)
    {
      this.modelListCod.push(this.machineSessioned.maC_ModelloCen);
    }
    if(this.machineSessioned.maC_ModelloDx!=null && this.modelListCod.findIndex(x=>x ==this.machineSessioned.maC_ModelloDx)<0 && this.machineSessioned.maC_ModelloDx!=0)
    {
      this.modelListCod.push(this.machineSessioned.maC_ModelloDx);
    }


    this.pieCharts=[];
    this.modelListCod.forEach((element,i) => {
      const flavours$ = this.flavourService.read(this.machineSessioned.maC_Numero_Serie_MS,element);
      const model$ = this.modelService.listObservable; 
      let  endDate = new Date();
      var startDate = new Date(); 
      startDate.setMonth(startDate.getMonth() - 3);
      const analysis$ = this.analysisService.read(this.machineSessioned.maC_Numero_Serie_MS,startDate,endDate);
      combineLatest(flavours$, model$,analysis$, (flavList, modelList,anaList) => ({flavList, modelList,anaList}))
      .subscribe(pair => {
        this.model.push(pair.modelList.find(x=>x.moD_Codice==element));
        this.flavoursArray.push({
          flvList : pair.flavList,
          code:element
        });

        this.analysisService.getList(this.machineSessioned.maC_Numero_Serie_MS,startDate,endDate).subscribe(
          x=>{
            this.dataSourceProduction = new MatTableDataSource(x);
            this.dataSourceProduction.paginator = this.paginator;
            this.dataSourceProduction.sort = this.sort;
          }
        )

        this.drawChart(element,i,pair.anaList);

      });
    });  
  }

  drawChart(element:number,index:number,analysisResp:FlavoursAnalysis[]){
    let labels=[];
    let data=[];
    analysisResp = analysisResp.filter(x=>x.scO_Peso_Sum>0);
    
    if(analysisResp.length>0){
      if(this.filter)
      {
        analysisResp = analysisResp.filter(x=> x.scO_SxDx==index+1);
      }

      let map = analysisResp.reduce((prev, next) =>{
        if (next.scO_Ricetta in prev) {
          prev[next.scO_Ricetta].scO_Peso_Sum += next.scO_Peso_Sum;
        } else {
           prev[next.scO_Ricetta] = next;
        }
        return prev;
      }, {});
      
     let result = Object.keys(map).map(scO_Ricetta => map[scO_Ricetta]);
      

      const totalScoPeso = result.reduce((a, b) => +a + +b.scO_Peso_Sum, 0);
      result.forEach(x => {
        const tmpFlavour = this.flavoursArray.find(x=>x.code==element).flvList.find(y => y.index === x.scO_Ricetta);
        const percentage = ((x.scO_Peso_Sum / totalScoPeso) * 100).toFixed(2).toString();
        
        
        if (tmpFlavour != null) {
          labels.push((tmpFlavour.locked? this.translateService.instant(tmpFlavour.name):tmpFlavour.name).toUpperCase() + ' (' + percentage + ' %)' + ' - ' + (x.scO_Peso_Sum / this.model[index].moD_Weight_Divider ) + ' kg');
          data.push(+percentage);
        } else {
          let singleLabel = "#"+ this.translateService.instant(this.model[index].moD_RMT.rmT_Code==2?'flavour':'recipe');
          const ricettaName = (x.scO_Ricetta === 0) ? this.translateService.instant('other') : singleLabel+" "+ x.scO_Ricetta.toString();
          labels.push(ricettaName.toUpperCase() + ' (' + percentage + ' %)' + ' - ' + (x.scO_Peso_Sum / this.model[index].moD_Weight_Divider) + ' kg');
          data.push(+percentage);
        }
      });
        this.pieCharts.push(new PieChart(labels,data));
    }
  }

  getDataAndDrawChart(fromDate:Date,todayDate:Date){
    const analysis$ = this.analysisService.read(this.machineSessioned.maC_Numero_Serie_MS,fromDate,todayDate).subscribe(x=>{
      this.modelListCod.forEach((element,i) => {
        this.drawChart(element,i,x);
      });
    });

    this.analysisService.getList(this.machineSessioned.maC_Numero_Serie_MS,fromDate,todayDate).subscribe(
      x=>{
        this.dataSourceProduction = new MatTableDataSource(x);
        this.dataSourceProduction.paginator = this.paginator;
        this.dataSourceProduction.sort = this.sort;
      }
    )



  }
}
