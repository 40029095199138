<mat-card class="mat-elevation-z8">
  <!-- Qcen {{ machineSessioned.macVersSwQcen }}
      QSX {{ machineSessioned.maC_VersSwQsx }}
      QDX {{ machineSessioned.maC_VersSwQdx }} -->
  <mat-label>
    {{ "last_communication" | translate }} {{countersObj.rgS_DateTime | date: "dd/MM/yyyy hh:mm:ss"}}
  </mat-label>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <!-- <mat-tab-group mat-align-tabs="center" selectIndex="2"> -->
  <!-- SINISTRA -->
  <!-- <mat-tab label="{{leftLabel}}">  -->
  <br /><mat-divider></mat-divider>
  <h3>{{leftLabel}}</h3>
  <div class="counters">
    <div class="counterContent">
      <div><img src="../../assets/img/Contaconi.png"></div>
      <div>
        {{ "strokecounter" | translate }}<br><b>{{countersObj.rgS_Contacolpi_Sx}} <br />
          ({{countersObj.rgS_Contacolpi_Sx * (machineSessioned.maC_Grammi / 1000) | number: '1.1'}} kg)</b>
        <div style="font-size: 1em;"
          *ngIf="machineSessioned.maC_VersSwQSx && machineSessioned.maC_ModelloSx !== 0 && machineSessioned.maC_ModelloSx !== null && machineSessioned.maC_ModelloDx !== 0 && machineSessioned.maC_ModelloDx !== null">
          <br />
          {{ "strokecounter" | translate }} {{ "mix1" | translate }}
          <br /><b>{{countersObj.rgS_Contacolpi_Mix}}</b>
          <br><b>({{countersObj.rgS_Contacolpi_Mix * (machineSessioned.maC_Grammi / 1000) | number: '1.1'}} kg)</b>
        </div>
      </div>
    </div>
    <div class="counterContent">
      <div><img src="../../assets/img/accensione.png"></div>
      <div>{{ "poweron" | translate }}<br><b>{{countersObj.rgS_Ore_Accensione_Sx}}</b></div>
    </div>
    <div class="counterContent">
      <div><img src="../../assets/img/compressore.png"></div>
      <div>{{ "compressor" | translate }}<br><b>{{countersObj.rgS_Ore_Compressore_Sx}}</b></div>
    </div>
    <div class="counterContent">
      <div><img src="../../assets/img/lavaggio.png"></div>
      <div>{{ "washing" | translate }}<br><b>{{countersObj.rgS_Ore_Lavaggio_Sx}}</b></div>
    </div>
    <div class="counterContent">
      <div><img src="../../assets/img/pompa.png"></div>
      <div>{{ "pumphour" | translate }}<br><b>{{countersObj.rgS_Ore_Pompa_Sx}}</b></div>
    </div>
    <div class="counterContent">
      <div><img src="../../assets/img/pompa.png"></div>
      <div>{{ "ore_agitazione_cilindro" | translate }}<br><b>{{countersObj.rgS_Ore_Agitazione_C_Sx}}</b></div>
    </div>
    <div class="counterContent">
      <div><img src="../../assets/img/pompa.png"></div>
      <div>{{ "ore_agitazione_vasca" | translate }}<br><b>{{countersObj.rgS_Ore_Agitazione_V_Sx}}</b></div>
    </div>
  </div>
  <!-- </mat-tab> -->
  <ng-container *ngIf="machineSessioned.maC_VersSwQCen">
    <br /><mat-divider></mat-divider>
    <h3>{{centerLabel}}</h3>
    <div class="counters">
      <div class="counterContent">
        <div><img src="../../assets/img/Contaconi.png"></div>
        <div>
          {{ "strokecounter" | translate }}<br><b>{{countersObj.rgS_Contacolpi_Cen}} <br />
            ({{countersObj.rgS_Contacolpi_Cen * (machineSessioned.maC_Grammi / 1000) | number: '1.1'}} kg)</b>
          <br /><br />
          {{ "strokecounter" | translate }} {{ "mix2" | translate }}<br>
          <b>{{countersObj.rgS_Contacolpi_Mix2}}</b><br />
          <b>({{countersObj.rgS_Contacolpi_Mix2 * (machineSessioned.maC_Grammi / 1000) | number: '1.1'}} kg)</b>
        </div>
      </div>
      <div class="counterContent">
        <div><img src="../../assets/img/accensione.png"></div>
        <div>{{ "poweron" | translate }}<br><b>{{countersObj.rgS_Ore_Accensione_Cen}}</b></div>
      </div>
      <div class="counterContent">
        <div><img src="../../assets/img/compressore.png"></div>
        <div>{{ "compressor" | translate }}<br><b>{{countersObj.rgS_Ore_Compressore_Cen}}</b></div>
      </div>
      <div class="counterContent">
        <div><img src="../../assets/img/lavaggio.png"></div>
        <div>{{ "washing" | translate }}<br><b>{{countersObj.rgS_Ore_Lavaggio_Cen}}</b></div>
      </div>
      <div class="counterContent">
        <div><img src="../../assets/img/pompa.png"></div>
        <div>{{ "pumphour" | translate }}<br><b>{{countersObj.rgS_Ore_Pompa_Cen}}</b></div>
      </div>
      <div class="counterContent">
        <div><img src="../../assets/img/pompa.png"></div>
        <div>{{ "ore_agitazione_cilindro" | translate }}<br><b>{{countersObj.rgS_Ore_Agitazione_C_Cen}}</b></div>
      </div>
      <div class="counterContent">
        <div><img src="../../assets/img/pompa.png"></div>
        <div>{{ "ore_agitazione_vasca" | translate }}<br><b>{{countersObj.rgS_Ore_Agitazione_V_Cen}}</b></div>
      </div>
    </div>
  </ng-container>
  <!-- </mat-tab> -->
  <!-- <mat-tab label="{{rightLabel}}" *ngIf="machineSessioned.macVersSwQdx"> -->
  <ng-container *ngIf="machineSessioned.maC_VersSwQDx">
    <br /><mat-divider></mat-divider>
    <h3>{{rightLabel}}</h3>
    <div class="counters">
      <div class="counterContent">
        <div><img src="../../assets/img/Contaconi.png"></div>
        <div>{{ "strokecounter" | translate }}<br><b>{{countersObj.rgS_Contacolpi_Dx}}<br />
            ({{countersObj.rgS_Contacolpi_Dx * (machineSessioned.maC_Grammi / 1000) | number: '1.1'}} kg)</b></div>
      </div>
      <div class="counterContent">
        <div><img src="../../assets/img/accensione.png"></div>
        <div>{{ "poweron" | translate }}<br><b>{{countersObj.rgS_Ore_Accensione_Dx}}</b></div>
      </div>
      <div class="counterContent">
        <div><img src="../../assets/img/compressore.png"></div>
        <div>{{ "compressor" | translate }}<br><b>{{countersObj.rgS_Ore_Compressore_Dx}}</b></div>
      </div>
      <div class="counterContent">
        <div><img src="../../assets/img/lavaggio.png"></div>
        <div>{{ "washing" | translate }}<br><b>{{countersObj.rgS_Ore_Lavaggio_Dx}}</b></div>
      </div>
      <div class="counterContent">
        <div><img src="../../assets/img/pompa.png"></div>
        <div>{{ "pumphour" | translate }}<br><b>{{countersObj.rgS_Ore_Pompa_Dx}}</b></div>
      </div>
      <div class="counterContent">
        <div><img src="../../assets/img/pompa.png"></div>
        <div>{{ "ore_agitazione_cilindro" | translate }}<br><b>{{countersObj.rgS_Ore_Agitazione_C_Dx}}</b></div>
      </div>
      <div class="counterContent">
        <div><img src="../../assets/img/pompa.png"></div>
        <div>{{ "ore_agitazione_vasca" | translate }}<br><b>{{countersObj.rgS_Ore_Agitazione_V_Dx}}</b></div>
      </div>
    </div>
  </ng-container>
  <!-- </mat-tab> -->
  <!-- </mat-tab-group> -->
</mat-card>
