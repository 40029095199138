import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Label } from 'ng2-charts';
import { SessionService } from '../services/session.service';
import { MacMacchine } from '../objects/macMacchine';
import { Router } from '@angular/router';



export class PieChart{
  labels:Label[];
  data:number[];
  colors: Array<any> = [
    { // all colors in order
      backgroundColor: [
        '#63b598', '#ce7d78', '#ea9e70', '#a48a9e', '#c6e1e8', '#648177', '#0d5ac1',
        '#f205e6', '#1c0365', '#14a9ad', '#4ca2f9', '#a4e43f', '#d298e2', '#6119d0',
        '#d2737d', '#c0a43c', '#f2510e', '#651be6', '#79806e', '#61da5e', '#cd2f00',
        '#9348af', '#01ac53', '#c5a4fb', '#996635', '#b11573', '#4bb473', '#75d89e',
        '#63b598', '#ce7d78', '#ea9e70', '#a48a9e', '#c6e1e8', '#648177', '#0d5ac1',
        '#f205e6', '#1c0365', '#14a9ad', '#4ca2f9', '#a4e43f', '#d298e2', '#6119d0',
        '#d2737d', '#c0a43c', '#f2510e', '#651be6', '#79806e', '#61da5e', '#cd2f00',
        '#9348af', '#01ac53', '#c5a4fb', '#996635', '#b11573', '#4bb473', '#75d89e',
        '#63b598', '#ce7d78', '#ea9e70', '#a48a9e', '#c6e1e8', '#648177', '#0d5ac1',
        '#f205e6', '#1c0365', '#14a9ad', '#4ca2f9', '#a4e43f', '#d298e2', '#6119d0']
      }
    ];

  constructor(labels:Label[],data:any[]) { 
      this.labels = labels;
      this.data= data;
   } 
}

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss'],
})
export class AnalysisComponent implements OnInit, OnDestroy {


  pieCharts: PieChart[]=[];

  machineSessioned: MacMacchine = new MacMacchine();

  public optionsFinder: string[] = ['1G', '1S', '1M', '3M', '6M', '1A', '2A'];

  constructor(
    protected route: Router,
    protected sessionService: SessionService
  ) {
  }

  ngOnInit() {
    this.machineSessioned = this.sessionService.currentMachineValue;   
  }

  showPie(item: string) {
    let myStartDate = new Date();
    let todayDate = new Date();
    let fromDate = new Date();
    fromDate.setHours(myStartDate.getHours() - 24);
    switch (item) {
      case '1G':
        fromDate.setHours(myStartDate.getHours() - 24);
        break;
      case '1S':
        fromDate.setHours(myStartDate.getHours() - 168);
        break;
      case '1M':
        fromDate.setMonth(myStartDate.getMonth() - 1);
        break;
      case '3M':
        fromDate.setMonth(myStartDate.getMonth() - 3);
        break;
      case '6M':
        fromDate.setMonth(myStartDate.getMonth() - 6);
        break;
      case '1A':
        fromDate.setMonth(myStartDate.getMonth() - 12);
        break;
      case '2A':
        fromDate.setMonth(myStartDate.getMonth() - 24);
        break;
    }

    this.pieCharts=[];
    this.getDataAndDrawChart(fromDate,todayDate);
      
  }

  getDataAndDrawChart(fromDate:Date,todayDate:Date):void{

  }

  ngOnDestroy(): void {
    this.pieCharts = [];
    this.route.dispose();
  }

}
