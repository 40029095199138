<div class="main-container">
  <div class="inside-main-container">

    <mat-card class="mat-elevation-z8">
      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'filter_label' | translate}}...">
      </mat-form-field>
      <table mat-table [dataSource]="dataSourceAssociation" matSort>

        <ng-container matColumnDef="maC_Matricola">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'serial' | translate}} Frigomat</th>
          <td mat-cell *matCellDef="let element"> {{element.maC_Matricola}} </td>
        </ng-container>

        <ng-container matColumnDef="maC_Numero_Serie_MS">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'serial' | translate}} MS </th>
          <td mat-cell *matCellDef="let element"> {{element.maC_Numero_Serie_MS}} </td>
        </ng-container>

        <ng-container matColumnDef="model">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'model' | translate}} </th>
          <td mat-cell *matCellDef="let element">{{(machinesModelsList | model :element.maC_Macchina)?.emA_Descrizione
            }}</td>
        </ng-container>

        <ng-container matColumnDef="maC_Indirizzo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'address' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.maC_Indirizzo}} </td>
        </ng-container>

        <ng-container matColumnDef="dealer">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'dealer' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{(userList | user :element.dealerId)?.utE_Nome }} </td>
        </ng-container>

        <ng-container matColumnDef="customer">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'customer' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{(userList | user :element.customerId)?.utE_Nome }} </td>
        </ng-container>

        <ng-container matColumnDef="maC_Pagamento">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'payment_state' | translate}} </th>
          <td mat-cell *matCellDef="let element"> <mat-icon style="font-size: 2em; text-align: center;"
              [ngStyle]="{ 'color' : element.maC_Pagamento | payments }">circle</mat-icon> </td>
        </ng-container>

        <ng-container matColumnDef="grams">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'grammi' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{ element.maC_Grammi }} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> {{ 'actions' | translate}} </th>
          <td mat-cell *matCellDef="let element">
            <button mat-raised-button color="primary" *ngIf="userSession.utE_Livello === userRole.Admin"
              (click)="editAssociation(element)">{{ 'edit' | translate}} </button>
            <button mat-button color="primary" *ngIf="userSession.utE_Livello === userRole.Admin"
              (click)="resetMatricola(element.maC_Numero_Serie_MS)">Reset matricola</button>
            <button color="primary"
              *ngIf="(userSession.utE_Livello==userRole.Admin || userSession.utE_Livello==userRole.Dealer) && element.maC_BloccoSx > 0 && element?.maC_ModelloSx === 1"
              mat-button (click)="lockUnlock(element.maC_Numero_Serie_MS, 2)">{{ 'unlock' | translate }}</button>
            <button color="warn"
              *ngIf="(userSession.utE_Livello==userRole.Admin || userSession.utE_Livello==userRole.Dealer) && (element.maC_BloccoSx === null || element.maC_BloccoSx < 1) && element?.maC_ModelloSx === 1"
              mat-button (click)="lockUnlock(element.maC_Numero_Serie_MS, 1)">{{ 'lock' | translate }}</button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100, 1000]" showFirstLastButtons></mat-paginator>
    </mat-card>
  </div>
</div>
