import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FlavoursAnalysisTouch } from 'src/app/objects/flavoursAnalysis';
import { AnalysisTouchService } from 'src/app/services/analysis-touch.service';
import { SessionService } from 'src/app/services/session.service';
import { AnalysisComponent, PieChart } from '../analysis.component';

@Component({
  selector: 'app-analysis-touch',
  templateUrl: './analysis-touch.component.html',
  styleUrls: ['./analysis-touch.component.scss']
})
export class AnalysisTouchComponent extends AnalysisComponent {

  loading:boolean;

  displayedColumns: string[] = ['name', 'weight', 'dateTime'];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSourceProduction;

  constructor(
    protected sessionService: SessionService,
    protected route: Router,
    private analysisService:AnalysisTouchService
  ) { 
    super(route,sessionService)
  }

  ngOnInit() {
    this.machineSessioned = this.sessionService.currentMachineValue;  
    this.pieCharts=[];
    let  endDate = new Date();
    var startDate = new Date(); 
    startDate.setMonth(startDate.getMonth() - 3);
    
    this.getDataAndDrawChart(startDate,endDate);
  }

  drawChart(analysisResp:FlavoursAnalysisTouch[]){
    let labels=[];
    let data=[];
    analysisResp = analysisResp.filter(x=>x.weight>0);
    
    if(analysisResp.length>0){
      
      const totalScoPeso = analysisResp .reduce((a, b) => +a + +b.weight, 0);
      analysisResp.forEach(x => {
        const tmpFlavour = x.name;
        const percentage = ((x.weight / totalScoPeso) * 100).toFixed(2).toString();
        
        
        if (tmpFlavour != null) {
          labels.push(tmpFlavour.toUpperCase() + ' (' + percentage + ' %)' + ' - ' + (x.weight ) + ' kg');
          data.push(+percentage);
        }
      });
        this.pieCharts.push(new PieChart(labels,data));
    }
  }

  getDataAndDrawChart(fromDate:Date,todayDate:Date){
    const analysis$ = this.analysisService.read(this.machineSessioned.maC_Numero_Serie_MS,fromDate,todayDate).subscribe(x=>{
      this.drawChart(x);
    });

    this.analysisService.getList(this.machineSessioned.maC_Numero_Serie_MS,fromDate,todayDate).subscribe(
      x=>{
        this.dataSourceProduction = new MatTableDataSource(x);
        this.dataSourceProduction.paginator = this.paginator;
        this.dataSourceProduction.sort = this.sort;
      }
    )
  }

}
