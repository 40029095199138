import { SessionService } from 'src/app/services/session.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from './../../services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UteUser } from 'src/app/objects/uteUser';
import { ModifyuserModalComponent } from '../modifyuser-modal/modifyuser-modal.component';

@Component({
  selector: 'app-policy-modal',
  templateUrl: './policy-modal.component.html',
  styleUrls: ['./policy-modal.component.scss']
})
export class PolicyModalComponent implements OnInit {

  public accepted : boolean = false;
  tmpUser: UteUser;

  constructor(
    public userService:UserService,
    public snackBarService: SnackbarService,
    public translateService: TranslateService,
    public sessionService:SessionService,
    public dialogRef: MatDialogRef<ModifyuserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.tmpUser = this.data.userSessioned;

  }

  acceptPolicy(){
    this.tmpUser.utE_Informativa = 1;
    // update UTE_Utenti table
    this.userService.informativaUpdate(this.tmpUser).subscribe(res => {
      this.snackBarService.showSnackBar(this.translateService.instant('operation_successfully'));
      //update the session storage
      let userLogged = this.sessionService.currentUserValue;
      userLogged.utE_Informativa = this.tmpUser.utE_Informativa;
      this.sessionService.setLoginSession(userLogged);
      this.dialogRef.close(true);
    },err => {
      console.log('Error add user: ', err.error);
    });
  }

}
