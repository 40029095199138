import { RecipeType } from "../utils/enums/recipeType";

export class Recipe
{

    static readonly ConsistencyMin = 60;
    static readonly ConsistencyMax = 250;

    consistency:Number;
    id:string;
    name:string;
}

export class RecipeSoft extends Recipe{
    conservationOff:Number;
    conservationOn:Number;
    creative:boolean;
    productionOff:Number;
    productionOn:Number;
    tankTemperature:Number;

    static readonly TankTempMin = 0;
    static readonly TankTempMax = 5;
    static readonly OnOffMin =1;
    static readonly OnOffMax =60;
}

export class RecipeSoftHt extends RecipeSoft{
    heatCycleTime:string;
    heatCyclesMaxTimeInterval:Number|string;
    holdTimeStep2:Number|string;
    maxTemperatureStep1:Number|string;
    minTemperatureStep3:Number|string;
    tCooling:string;
    tHeating:string;
    tHolding:string;

    static readonly HoldTimeMin = 30;
    static readonly HoldTimeMax = 60;
    static readonly HoldTimeDef = 45;
    static readonly MaxTemperatureStep1Min = 65;
    static readonly MaxTemperatureStep1Max = 69;
    static readonly MaxTemperatureStep1Def = 70;
    static readonly MinTemperatureStep3Min = 0;
    static readonly MinTemperatureStep3Max = 5;
    static readonly MinTemperatureStep3Def = 4;
    static readonly TimeIntervalMin =0;
    static readonly TimeIntervalMax =7;
}

export class RecipeTwist extends Recipe
{
    type:RecipeType;
    turbo:Number;
    duration:Number;

    static readonly TurboMin = 0;
    static readonly TurboMax = 300;
    static readonly DurationMin = 60;
    static readonly DurationMaxSemiAuto = 1800;
    static readonly DurationMaxSlush = 600;
    static readonly ConsistencyMinSlush = 90;
    static readonly ConsistencyMaxSlush = 180;

    public get showConsistency():boolean{
        return [RecipeType.SemiAuto,RecipeType.Slush].findIndex(x=> x===this.type)>-1;
    } 
    public get showTurbo():boolean{
        return [RecipeType.Auto,RecipeType.AutoHard,RecipeType.SemiAuto,RecipeType.SemiAutoTime].findIndex(x=> x===this.type)>-1;
    }
    public get showDuration():boolean{
        return [RecipeType.SemiAutoTime,RecipeType.CoffeeSlush].findIndex(x=> x===this.type)>-1;
    }
}