import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SalStoricoAllarmi } from '../objects/salStoricoAllarmi';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AlarmHistoryService {
  endpoint: string;
  apiName:string='alarmsHistory';

  constructor( protected http: HttpClient, protected appConfigService:AppConfigService)
  {
    this.http=http;
    this.endpoint = appConfigService.apiBaseUrl;
  }

  read(serialNumberMs:number): Observable<SalStoricoAllarmi[]> {
    //this.loading = true;

    console.log(this.apiName);
    return this.http.get<SalStoricoAllarmi[]>(`${this.endpoint}/${this.apiName}?serialMS=${serialNumberMs}`);
  }
}
