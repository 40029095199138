import { Injectable } from '@angular/core';
import PubSub, { AWSIoTProvider } from '@aws-amplify/pubsub';
import Amplify ,{Auth} from 'aws-amplify';
import { Subscription } from 'zen-observable';
import { environment } from 'src/environments/environment';
import { AuthCognitoService } from './auth-cognito.service';

@Injectable({
  providedIn: 'root'
})

export class AwsIotProviderService {

  constructor(
    authCognitoService:AuthCognitoService
  ) {
    
    Amplify.configure({
      Auth:{
        identityPoolId: environment.aws.identityPoolId,
        region:environment.aws.region,
        userPoolId:environment.aws.userPoolId,
        userPoolWebClientId: environment.aws.userPoolWebClientId
      },
    })
    Amplify.addPluggable(new AWSIoTProvider({
      aws_pubsub_region: environment.aws.region,
      aws_pubsub_endpoint: 'wss://'+environment.aws.iotEndpoint+'/mqtt',
    }));
    authCognitoService.signIn("Microsystems","$Microsystems19");    
  }

  public publish(topic:string,message:any=''){
    PubSub.publish(topic, message ).then(()=>{
      console.log("Message sent: " + topic);
    })
  }

  public subscribe(topic:string){
    let a = PubSub.subscribe(topic);
    return a;
  }

  public unsubscribe(sub:Subscription){
    sub.unsubscribe();
  }
}
